import { ContainerLoad } from "./styled";


export default function Load() {
    return (
        <ContainerLoad>
            <div className="loading"></div>
            <h3>Carregando...</h3>
        </ContainerLoad>
    )
}