import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight, BiSearch } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { SelectBox, TextBox } from 'devextreme-react';
import { useCallback, useEffect, useState } from 'react';
import api, { cepApi } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppStore } from '../../store';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { UF } from '../../data/data';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { BiPlus } from 'react-icons/bi';
import municipiosData from '../../data/municipios.json';
import {
  removeSpacesAndSpecialChars,
  validateEmail,
} from '../../utils/StringUtils';
import { Validator, EmailRule } from 'devextreme-react/validator';
import { IMunicipio } from '../../components/AutoCompleteCustom';
import { Producer } from '../../store/types';
import AutoCompleteString from '../../components/AutoCompleteString';
import Load from '../../components/Load';

export const FormStep3 = () => {
  const navigate = useNavigate();
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const isLoading = useAppStore((store) => store.isLoading);

  const [producer_document, setProducerDocument] = useState(
    subscription?.producer?.document
  );
  const [is_enterprise, setIsEnterprise] = useState(
    subscription?.producer?.is_enterprise
  );

  //fields popup
  const [load, setLoad] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [document, setDocument] = useState('');
  const [zip_code, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [name_resp, setNameResp] = useState('');
  const [phone_resp, setPhoneResp] = useState('');
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [
    isPopupCPFCNPJAlreadyExistsVisible,
    setIsPopupCPFCNPJAlreadyExistsVisible,
  ] = useState(false);
  const [
    msgPopupCPFCNPJAlreadyExistsVisible,
    setMsgPopupCPFCNPJAlreadyExistsVisible,
  ] = useState('');
  const [nomeExisting, setNomeExisting] = useState('');
  const [cidadeExisting, setCidadeExisting] = useState('');

  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);
  const config = useAppStore((store) => store.config);

  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');
  const [municipiosPopup, setMunicipiosPopup] = useState(municipiosData);

  const updateDatasource = (e: string) => {
    setMunicipiosPopup(municipiosData.filter((i) => i['UF'] === e));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function clearFields() {
    setZipCode('');
    setNumber('');
    setComplement('');
    setDistrict('');
  }

  useEffect(() => {
    if (subscription_id !== null && subscription_id !== subscription.id) {
      loadSubscription(subscription_id);
    }
    setProducerDocument(subscription.producer?.document);
    setIsEnterprise(subscription.producer?.is_enterprise);
  }, [subscription_id, subscription, loadSubscription]);

  const getPopupTitle = () => {
    if (!is_enterprise) {
      return 'Cadastro de Produtores';
    } else {
      return 'Cadastro de Empresas';
    }
  };

  const getPopupOperationName = () => {
    if (!is_enterprise) {
      return 'Produtor';
    } else {
      return 'Empresa';
    }
  };

  const togglePopup = () => setPopupVisibility(!isPopupVisible);

  const producer_company_type = ['Produtor', 'Empresa'];
  const clearPopupFields = () => {
    setName('');
    setEmail('');
    setPhone('');
    setDocument('');
    setAddress('');
    setCity('');
    setUf('');
    setNameResp('');
    setPhoneResp('');
  };

  const findProducerByDocument = useCallback(
    async (document: string, pop_up = true) => {
      const pure = removeSpacesAndSpecialChars(document);
      let result = undefined;

      await api
        .post('/subscription/check-producer', { document: pure })
        .then((res) => {
          result = res.data;
          setMsgPopupCPFCNPJAlreadyExistsVisible(
            `O CPF/CNPJ informado já está cadastrado para [ ${res.data.name} ]`
          );
          setNomeExisting(res.data.name);
          setCidadeExisting(res.data.city);
          setIsPopupCPFCNPJAlreadyExistsVisible(pop_up);

          pop_up &&
            toast.info(
              `O CPF/CNPJ informado já está cadastrado para [ ${res.data.name} ]`
            );
        })
        .catch((error) => {
          console.log(error.response.data.message);
          setMsgPopupCPFCNPJAlreadyExistsVisible(
            'O CPF/CNPJ informado ainda não está cadastrado.'
          );
          // setIsPopupCPFCNPJAlreadyExistsVisible(true)
          toast.info('O CPF/CNPJ informado ainda não está cadastrado.');
        });
      return result as unknown as Producer | undefined;
    },
    []
  );

  const validateFieldsPopup = () => {
    let validateCompanyFields = is_enterprise
      ? name_resp === '' || phone_resp === ''
      : true;
    return (
      !(
        name === '' ||
        email === '' ||
        phone === '' ||
        document === '' ||
        address === '' ||
        zip_code === '' ||
        number === '' ||
        city === '' ||
        uf === ''
      ) || !validateCompanyFields
    );
  };

  useEffect(() => {
    if (zip_code) {
      const pure = removeSpacesAndSpecialChars(zip_code);
      if (pure.length === 8) {
        setLoad(true);
        cepApi
          .get(`/${pure}`)
          .then((res) => {
            setAddress(res.data.street);
            setDistrict(res.data.neighborhood);
            setCity(res.data.city);
            setUf(res.data.state);
          })
          .catch((error) => {
            toast.error('Error ao buscar o cep.');
          })
          .finally(() => {
            setLoad(false);
          });
      }
    }
  }, [zip_code]);

  const createProducer = () => {
    if (!validateFieldsPopup()) {
      return toast.error('Atenção! preencha todos os campos.');
    }

    if (!validateEmail(email)) {
      return toast.error('Atenção! Preencha um email válido.');
    }

    const cpfCnpjPure = removeSpacesAndSpecialChars(document!);

    if (!is_enterprise) {
      if (!cpf.isValid(cpfCnpjPure || '')) {
        return toast.error('Preencha um CPF válido!');
      }
    } else {
      if (!cnpj.isValid(cpfCnpjPure || '')) {
        return toast.error('Preencha um CNPJ válido!');
      }
    }

    api
      .post('/subscription/producer', {
        name,
        email,
        phone,
        document: cpfCnpjPure,
        zip_code,
        address,
        number,
        complement,
        district,
        uf,
        city,
        name_resp,
        phone_resp,
        is_enterprise,
      })
      .then((res) => {
        if (res.status === 201) {
          setProducerDocument(document);
          setPopupVisibility(false);
          toast.success('Registro criado!');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      })
      .finally(() => {
        // listProdutoresEmpresas();
      });
  };

  const handleSave = useCallback(async () => {
    if (producer_document === null || producer_document === '') {
      return toast.error('Atenção! preencha todos os campos.');
    }

    let cpfCnpjProdPure: string = '';
    let cnpjPure: string = '';
    if (!is_enterprise) {
      cpfCnpjProdPure = removeSpacesAndSpecialChars(producer_document!);
      if (!cpf.isValid(cpfCnpjProdPure || '')) {
        return toast.error('Preencha um CPF válido!');
      }
    } else {
      cnpjPure = removeSpacesAndSpecialChars(producer_document!);
      if (!cnpj.isValid(cnpjPure || '')) {
        return toast.error('Preencha um CNPJ válido!');
      }
    }

    const valid = await findProducerByDocument(producer_document!, false);

    if (valid === undefined) {
      return;
    }

    api
      .patch(`/subscription/producer`, {
        subscription_id: subscription.id,
        producer_id: valid.id!,
        current_step: 3,
      })
      .then((res) => {
        if (res.status === 200) {
          setSubscription(res.data);
          if (subscription.current_step < 3) {
            setStep(3);
          }
          toast.success('Salvo com sucesso');
          navigate('/passo4');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      });
  }, [
    producer_document,
    is_enterprise,
    navigate,
    subscription,
    setSubscription,
    setStep,
    findProducerByDocument,
  ]);

  return (
    <C.Container>
      {load && <Load />}
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Dados pessoais e de propriedade' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>
        {isLoading && (
          <C.ContentRightForm>
            <div
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Title title='Carregando...' />
            </div>
          </C.ContentRightForm>
        )}

        {!isLoading && (
          <C.ContentRightForm>
            <C.WrapperCheckbox>
              <input
                type='checkbox'
                checked={is_enterprise}
                onChange={(e) => setIsEnterprise(e.target.checked)}
              />
              <span>Desejo me inscrever como empresa</span>
            </C.WrapperCheckbox>
            <C.BoxFields>
              <C.ContentRightFormBoxLeft>
                <TextBox
                  value={config.safra}
                  stylingMode='underlined'
                  labelMode='static'
                  disabled
                  label='Safra (*)'
                  height={50}
                />
                <br />

                <C.GroupInputs>
                  {!is_enterprise ? (
                    <C.BoxInput>
                      <TextBox
                        value={producer_document}
                        onValueChange={(e) => setProducerDocument(e)}
                        stylingMode='underlined'
                        labelMode='static'
                        label='CPF do produtor (*)'
                        height={50}
                        width='100%'
                        maxLength={13}
                        mask='000.000.000-00'
                      />
                    </C.BoxInput>
                  ) : (
                    <C.BoxInput>
                      <TextBox
                        value={producer_document}
                        onValueChanged={(e) => {
                          setProducerDocument(e.value);
                        }}
                        stylingMode='underlined'
                        labelMode='static'
                        label='CNPJ empresa (*)'
                        height={50}
                        width='100%'
                        maxLength={18}
                        mask='00.000.000/0000-00'
                      />
                    </C.BoxInput>
                  )}
                  <C.BoxInput className='alignBottom'>
                    <BiSearch
                      size={22}
                      onClick={() => {
                        findProducerByDocument(producer_document!);
                      }}
                      title='Verificar'
                    />
                  </C.BoxInput>
                  <C.BoxInput>
                    <Button
                      onClick={() => {
                        togglePopup();
                      }}
                      title='Cadastrar'
                      color='gray'
                      style={{ width: '33%' }}
                    />
                  </C.BoxInput>
                  <br />
                </C.GroupInputs>
              </C.ContentRightFormBoxLeft>
            </C.BoxFields>

            <C.BoxButtonSubmit>
              <Button
                type='button'
                onClick={() => {
                  setStep(0);
                  navigate(`/passo2`);
                }}
                title='VOLTAR'
                color='#2F80ED'
                iconLeft={<BiChevronLeft fontSize={22} />}
              />
              <Button
                type='button'
                onClick={handleSave}
                title='SALVAR E AVANÇAR'
                color='#27AE60'
                iconRight={<BiChevronRight fontSize={22} />}
              />
            </C.BoxButtonSubmit>
          </C.ContentRightForm>
        )}

        <Popup
          title={getPopupTitle()}
          visible={isPopupVisible}
          hideOnOutsideClick={true}
          onHiding={(e) => {
            togglePopup();
            clearPopupFields();
          }}
          width={'95%'}
          height={'95vh'}
          onShowing={(e) => {
            clearPopupFields();
          }}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <SelectBox
                items={producer_company_type}
                // value={type}
                onValueChange={(e) => {
                  setIsEnterprise(e === 'Empresa');
                }}
                stylingMode='underlined'
                labelMode='static'
                label='Tipo (*)'
                width='100%'
                height={50}
                style={{ marginRight: '20px', color: '#444' }}
              />
              <TextBox
                stylingMode='underlined'
                value={name}
                labelMode='static'
                label={`Nome ${
                  is_enterprise ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Digite um nome...'
                width='100%'
                height={50}
                onValueChanged={(e) => {
                  setName(e.value);
                }}
              />
              <TextBox
                stylingMode='underlined'
                // value={email}
                labelMode='static'
                label={`Email ${
                  is_enterprise ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Email'
                width='100%'
                height={50}
                onValueChanged={(e) => setEmail(e.value)}
              >
                <Validator>
                  <EmailRule message='Informe um e-mail  válido!' />
                </Validator>
              </TextBox>
              <TextBox
                stylingMode='underlined'
                // value={phone}
                labelMode='static'
                label={`Telefone ${
                  is_enterprise ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Um telefone...'
                width='100%'
                height={50}
                mask='(00) 0 0000-0000'
                onValueChanged={(e) => setPhone(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={document}
                labelMode='static'
                label={`${is_enterprise ? 'CNPJ Empresa' : 'CPF Produtor'} (*)`}
                placeholder='Um CPF ou CNPJ...'
                width='100%'
                height={50}
                mask={is_enterprise ? '00.000.000/0000-00' : '000.000.000-00'}
                onValueChanged={(e) => setDocument(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                label='CEP'
                value={zip_code}
                labelMode='static'
                placeholder='00.000-000'
                width='100%'
                height={50}
                mask='00.000-000'
                onValueChanged={(e) => setZipCode(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={address}
                labelMode='static'
                label={`Endereço ${
                  is_enterprise ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Um endereço...'
                width='100%'
                height={50}
                onValueChanged={(e) => setAddress(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={number}
                labelMode='static'
                label={`N° (*)`}
                placeholder='Um número...'
                width='100%'
                height={50}
                onValueChanged={(e) => setNumber(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={district}
                labelMode='static'
                label={`Bairro (*)`}
                placeholder='Um bairro...'
                width='100%'
                height={50}
                onValueChanged={(e) => setDistrict(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={complement}
                labelMode='static'
                label='Complemento'
                placeholder='Um complemento...'
                width='100%'
                height={50}
                onValueChanged={(e) => setComplement(e.value)}
              />
              <SelectBox
                items={UF}
                value={uf}
                onValueChange={(e) => {
                  setUf(e);
                  updateDatasource(e);
                }}
                stylingMode='underlined'
                labelMode='static'
                label={`UF ${is_enterprise ? 'da empresa' : 'do produtor'} (*)`}
                width='100%'
                height={50}
                style={{ marginRight: '20px', color: '#444' }}
              />
              <AutoCompleteString<IMunicipio>
                itemsAvailable={municipiosPopup}
                valueExpr='MUN'
                searchExpr='MUN'
                inputValue={city}
                label={`Cidade ${getPopupOperationName()}(*)`}
                labelMode='static'
                stylingMode='underlined'
                height={50}
                searchMode='startswith'
                typeData='municipio'
                onValueChanged={(e) => setCity(e)}
              />
              {is_enterprise && (
                <TextBox
                  stylingMode='underlined'
                  // value={nome_resp}
                  labelMode='static'
                  label='Nome do Responsável Empresa(*)'
                  placeholder='Um nome de responsável...'
                  width='100%'
                  height={50}
                  onValueChanged={(e) => setNameResp(e.value)}
                />
              )}
              {is_enterprise && (
                <TextBox
                  stylingMode='underlined'
                  // value={phone_resp}
                  labelMode='static'
                  label='Telefone do Responsável Empresa(*)'
                  mask='(00) 00000-0000'
                  placeholder='Um telefone...'
                  width='100%'
                  height={50}
                  onValueChanged={(e) => setPhoneResp(e.value)}
                />
              )}
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button onClick={createProducer}>
                <BiPlus size={18} />
                Salvar
              </button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>

        <Popup
          title='Atenção!'
          visible={isPopupCPFCNPJAlreadyExistsVisible}
          hideOnOutsideClick={true}
          width={340}
          height={280}
          onHidden={(e) => {
            setIsPopupCPFCNPJAlreadyExistsVisible(false);
          }}
          onShowing={(e) => {
            // setIsPopupCPFCNPJAlreadyExistsVisible(false);
            clearPopupFields();
          }}
        >
          <Position at='center' my='center' />
          <ToolbarItem
            widget='dxButton'
            toolbar='bottom'
            location='after'
            render={() => (
              <button
                onClick={() => setIsPopupCPFCNPJAlreadyExistsVisible(false)}
                style={{
                  background: '#2F80ED',
                  border: 'none',
                  padding: '0.5rem 1rem',
                  borderRadius: 5,
                  color: '#fff',
                  cursor: 'pointer',
                }}
              >
                Ok
              </button>
            )}
          />
          <p>{msgPopupCPFCNPJAlreadyExistsVisible}</p>
          <br />
          <TextBox
            label={'Nome:'}
            value={nomeExisting}
            visible={true}
            readOnly={true}
            // labelMode="static"
            stylingMode='underlined'
          />{' '}
          <br />
          <TextBox
            label={'Cidade:'}
            value={cidadeExisting}
            visible={true}
            readOnly={true}
            labelMode='static'
            stylingMode='underlined'
          />{' '}
          <br />
        </Popup>
      </C.ContentRight>
      {/* )} */}
    </C.Container>
  );
};
