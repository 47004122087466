/* eslint-disable @typescript-eslint/no-unused-vars */
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight, BiPlusCircle } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '../../components/Tooltip';
import { HelpTsiOnFarm, OndeRealizou } from '../../data/data';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { useAppStore } from '../../store';

import NumericTextBox from '../../components/NumericTextBox';
import { Manufacturer, ProtectionProduct } from '../../store/types';
import AutoCompleteString from '../../components/AutoCompleteString';
import AutoCompleteCustom from '../../components/AutoCompleteCustom';

export const FormStep10 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);
  const defensivosData = useAppStore((store) => store.defensives);
  const maquinarioData = useAppStore((store) => store.manufacturers);

  const navigate = useNavigate();

  const [tratamento_semente, setTratSement] = useState(
    subscription.seed_treatment?.accomplished
  );
  const [inoculate, setInoculate] = useState(
    subscription.seed_treatment?.inoculate
  );
  const [biostimulant, setBiostimulant] = useState(
    subscription.seed_treatment?.biostimulant
  );
  const [defensive_quantity, setDefensiveQuantity] = useState(
    subscription.seed_treatment?.defensive_quantity
  );
  const [nome_pacte_trat_sement_1, setPctTratSemente1] = useState<
    undefined | string
  >(subscription?.seed_treatment?.def_1);
  const [nome_pacte_trat_sement_2, setPctTratSemente2] = useState<
    undefined | string
  >(subscription?.seed_treatment?.def_2);
  const [nome_pacte_trat_sement_3, setPctTratSemente3] = useState<
    undefined | string
  >(subscription?.seed_treatment?.def_3);
  const [nome_pacte_trat_sement_4, setPctTratSemente4] = useState<
    undefined | string
  >(subscription?.seed_treatment?.def_4);

  const [fabricante_semeadora, setFabricanteSemeadora] = useState(
    subscription.seed_treatment?.manufacturer_seeder
  );
  const [ano_semeadora, setAnoSemeadora] = useState(
    subscription.seed_treatment?.year_seeder
  );
  const [modelo_semeadora, setMarcaSemeadora] = useState(
    subscription.seed_treatment?.model_seeder
  );

  const [fabricante_pulverizador, setFabricantePulverizador] = useState(
    subscription.seed_treatment?.manufacturer_sprayer
  );
  const [modelo_pulverizador, setMarcaPulverizador] = useState(
    subscription.seed_treatment?.model_sprayer
  );
  const [ano_pulverizador, setAnoPulverizador] = useState(
    subscription.seed_treatment?.year_sprayer
  );

  const [fabricante_colhedora, setFabricanteColhedora] = useState(
    subscription.seed_treatment?.manufacturer_harverster
  );
  const [modelo_colhedora, setModeloColhedora] = useState(
    subscription.seed_treatment?.model_harverster
  );
  const [ano_colhedora, setAnoColhedora] = useState(
    subscription.seed_treatment?.year_harverster
  );

  const handleSubmit = useCallback(async () => {
    if (
      tratamento_semente !== undefined &&
      !tratamento_semente.includes('3') &&
      (inoculate === undefined ||
        biostimulant === undefined ||
        defensive_quantity === undefined ||
        inoculate === '' ||
        biostimulant === '' ||
        inoculate === null ||
        biostimulant === null ||
        defensive_quantity === null)
    ) {
      return toast.error('Preencha os campos de tratamento de sementes!');
    }

    if (
      fabricante_semeadora === null ||
      ano_semeadora === null ||
      modelo_semeadora === null ||
      fabricante_pulverizador === null ||
      modelo_pulverizador === null ||
      ano_pulverizador === null ||
      fabricante_colhedora === null ||
      modelo_colhedora === null ||
      ano_colhedora === null ||
      fabricante_semeadora === undefined ||
      ano_semeadora === undefined ||
      modelo_semeadora === undefined ||
      fabricante_pulverizador === undefined ||
      modelo_pulverizador === undefined ||
      ano_pulverizador === undefined ||
      fabricante_colhedora === undefined ||
      modelo_colhedora === undefined ||
      ano_colhedora === undefined ||
      modelo_semeadora === '' ||
      modelo_pulverizador === '' ||
      modelo_colhedora === ''
    ) {
      return toast.error('Preencha todos os campos de maquinário!');
    }

    const year = new Date().getFullYear();

    if (
      ano_colhedora > year ||
      ano_pulverizador > year ||
      ano_semeadora > year
    ) {
      return toast.error('Ano dos maquinários incorreto!');
    }

    api
      .patch('/subscription/add-seed', {
        subscription_id: subscription.id,
        current_step: 10,
        accomplished: tratamento_semente,
        inoculate,
        biostimulant,
        defensive_quantity,
        def_1: nome_pacte_trat_sement_1,
        def_2: nome_pacte_trat_sement_2,
        def_3: nome_pacte_trat_sement_3,
        def_4: nome_pacte_trat_sement_4,
        manufacturer_seeder_id: fabricante_semeadora.id,
        year_seeder: Number(ano_semeadora),
        model_seeder: modelo_semeadora,
        manufacturer_sprayer_id: fabricante_pulverizador.id,
        model_sprayer: modelo_pulverizador,
        year_sprayer: Number(ano_pulverizador),
        manufacturer_harverster_id: fabricante_colhedora.id,
        model_harverster: modelo_colhedora,
        year_harverster: Number(ano_colhedora),
      })
      .then((res) => {
        if (res.status === 200) {
          setSubscription(res.data);
          if (subscription.current_step < 10) {
            setStep(10);
          }
          toast.success('Dados salvos com sucesso!');
          navigate('/passo11');
        }
      })
      .catch(() => toast.error('Ops! Ocorreu um erro, tente novamente'));
  }, [
    navigate,
    setStep,
    inoculate,
    biostimulant,
    defensive_quantity,
    subscription,
    tratamento_semente,
    nome_pacte_trat_sement_1,
    nome_pacte_trat_sement_2,
    nome_pacte_trat_sement_3,
    nome_pacte_trat_sement_4,
    ano_semeadora,
    modelo_semeadora,
    modelo_pulverizador,
    ano_pulverizador,
    fabricante_semeadora,
    fabricante_pulverizador,
    fabricante_colhedora,
    modelo_colhedora,
    ano_colhedora,
    setSubscription,
  ]);

  console.log(defensivosData);

  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');

  useEffect(() => {
    if (subscription_id !== null && subscription_id !== subscription.id) {
      loadSubscription(subscription_id);
    }
    setTratSement(subscription.seed_treatment?.accomplished);
    setInoculate(subscription.seed_treatment?.inoculate);
    setBiostimulant(subscription.seed_treatment?.biostimulant);
    setDefensiveQuantity(subscription.seed_treatment?.defensive_quantity);
    setPctTratSemente1(subscription.seed_treatment?.def_1);
    setPctTratSemente2(subscription.seed_treatment?.def_2);
    setPctTratSemente3(subscription.seed_treatment?.def_3);
    setPctTratSemente4(subscription.seed_treatment?.def_4);
    setFabricanteSemeadora(subscription.seed_treatment?.manufacturer_seeder);
    setAnoSemeadora(subscription.seed_treatment?.year_seeder);
    setMarcaSemeadora(subscription.seed_treatment?.model_seeder);
    setFabricantePulverizador(
      subscription.seed_treatment?.manufacturer_sprayer
    );
    setMarcaPulverizador(subscription.seed_treatment?.model_sprayer);
    setAnoPulverizador(subscription.seed_treatment?.year_sprayer);
    setFabricanteColhedora(
      subscription.seed_treatment?.manufacturer_harverster
    );
    setModeloColhedora(subscription.seed_treatment?.model_harverster);
    setAnoColhedora(subscription.seed_treatment?.year_harverster);
  }, [loadSubscription, subscription, subscription_id]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.ContentRightForm>
          <C.HeaderContentRight>
            <Title title='Informações da área inscrita: Implantação da cultura' />
            <span>(*) Campos obrigatórios</span>
          </C.HeaderContentRight>
          <br />
          <br />
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.InfoSection>Tratamento de sementes</C.InfoSection>
              <C.GroupInputs>
                <C.BoxInput>
                  <div
                    style={{
                      marginLeft: '100px',
                      marginBottom: '-20px',
                      position: 'absolute',
                      zIndex: '1000',
                      color: '#2F80ED',
                    }}
                  >
                    <Tooltip
                      info={HelpTsiOnFarm}
                      icon={<AiFillQuestionCircle />}
                    />
                  </div>
                  <SelectBox
                    value={tratamento_semente}
                    onValueChange={(e) => setTratSement(e)}
                    items={OndeRealizou}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Onde realizou? (*)'
                    placeholder='Selecione'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>

                {tratamento_semente !== undefined &&
                  tratamento_semente !== null &&
                  tratamento_semente !== '' &&
                  !(tratamento_semente.indexOf('3') >= 0) && (
                    <>
                      <C.BoxInput>
                        <TextBox
                          value={inoculate}
                          onValueChange={(e) => setInoculate(e)}
                          stylingMode='underlined'
                          labelMode='static'
                          label='Inoculantes (*)'
                          width='100%'
                          height={50}
                        />
                      </C.BoxInput>

                      <C.BoxInput>
                        <TextBox
                          value={biostimulant}
                          onValueChange={(e) => setBiostimulant(e)}
                          stylingMode='underlined'
                          labelMode='static'
                          label='Bioestimulantes (*)'
                          width='100%'
                          height={50}
                        />
                      </C.BoxInput>

                      <C.BoxInputWithBtnPlus>
                        <NumberBox
                          value={
                            defensive_quantity !== undefined
                              ? defensive_quantity
                              : undefined
                          }
                          onValueChange={setDefensiveQuantity}
                          stylingMode='underlined'
                          labelMode='static'
                          label='Quantidade de defensivos '
                          width='100%'
                          height={50}
                          max={4}
                        />
                        <span>
                          <BiPlusCircle
                            fontSize={28}
                            style={{ marginRight: 10 }}
                            onClick={() => {}}
                          />
                        </span>
                      </C.BoxInputWithBtnPlus>

                      {Number(defensive_quantity) >= 1 && (
                        <C.BoxInput style={{ margin: '10px 0' }}>
                          <AutoCompleteString<ProtectionProduct>
                            itemsAvailable={defensivosData}
                            valueExpr='name'
                            searchExpr='name'
                            inputValue={nome_pacte_trat_sement_1}
                            label='Defensivo 1'
                            // onValueChanged={(e) => setPctTratSemente(e.value)}
                            labelMode='static'
                            stylingMode='underlined'
                            height={50}
                            width='100%'
                            searchMode='startswith'
                            typeData='defensivo'
                            onValueChanged={(e) => setPctTratSemente1(e)}
                          />
                        </C.BoxInput>
                      )}

                      {Number(defensive_quantity) >= 2 && (
                        <C.BoxInput style={{ margin: '10px 0' }}>
                          <AutoCompleteString<ProtectionProduct>
                            itemsAvailable={defensivosData}
                            valueExpr='name'
                            searchExpr='name'
                            inputValue={nome_pacte_trat_sement_2}
                            label='Defensivo 2'
                            // onValueChanged={(e) => setPctTratSemente2(e.value)}
                            labelMode='static'
                            stylingMode='underlined'
                            height={50}
                            width='100%'
                            typeData='defensivo'
                            searchMode='startswith'
                            onValueChanged={(e) => {
                              setPctTratSemente2(e);
                            }}
                          />
                        </C.BoxInput>
                      )}

                      {Number(defensive_quantity) >= 3 && (
                        <C.BoxInput style={{ margin: '10px 0' }}>
                          <AutoCompleteString<ProtectionProduct>
                            itemsAvailable={defensivosData}
                            valueExpr='name'
                            searchExpr='name'
                            inputValue={nome_pacte_trat_sement_3}
                            label='Defensivo 3'
                            // onValueChanged={(e) => setPctTratSemente3(e.value)}
                            labelMode='static'
                            stylingMode='underlined'
                            height={50}
                            width='100%'
                            typeData='defensivo'
                            searchMode='startswith'
                            onValueChanged={(e) => setPctTratSemente3(e)}
                          />
                        </C.BoxInput>
                      )}

                      {Number(defensive_quantity) >= 4 && (
                        <C.BoxInput style={{ margin: '10px 0' }}>
                          <AutoCompleteString<ProtectionProduct>
                            itemsAvailable={defensivosData}
                            valueExpr='name'
                            searchExpr='name'
                            inputValue={nome_pacte_trat_sement_4}
                            label='Defensivo 4'
                            labelMode='static'
                            stylingMode='underlined'
                            height={50}
                            width='100%'
                            typeData='defensivo'
                            searchMode='startswith'
                            onValueChanged={(e) => setPctTratSemente4(e)}
                          />
                        </C.BoxInput>
                      )}
                    </>
                  )}
              </C.GroupInputs>

              <C.HeaderContentRight>
                <Title title='Maquinário' />
              </C.HeaderContentRight>
              <br />
              <br />
              <C.InfoSection>Semeadora</C.InfoSection>

              <C.GroupInputs2>
                <C.BoxInput>
                  <AutoCompleteCustom<Manufacturer>
                    itemsAvailable={maquinarioData}
                    valueExpr='name'
                    searchExpr='name'
                    inputValue={fabricante_semeadora?.name}
                    label='Fabricante da semeadora (*)'
                    labelMode='static'
                    stylingMode='underlined'
                    height={50}
                    typeData='maquinario'
                    searchMode='startswith'
                    onValueChanged={(e) => setFabricanteSemeadora(e)}
                    // onValueChanged={handleValueChangeMaquinario(e, setFabricanteSemeadora(e.value))}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <TextBox
                    value={modelo_semeadora}
                    onValueChange={(e) => setMarcaSemeadora(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Modelo da semeadora(*)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <NumberBox
                    value={
                      ano_semeadora !== undefined ? ano_semeadora : undefined
                    }
                    onValueChange={(e) => setAnoSemeadora(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Ano da semeadora (*)'
                    width='100%'
                    height={50}
                    max={new Date().getFullYear()}
                  />
                </C.BoxInput>
              </C.GroupInputs2>

              <br />
              <br />
              <C.InfoSection>Pulverizador</C.InfoSection>

              <C.GroupInputs2>
                <C.BoxInput>
                  <AutoCompleteCustom<Manufacturer>
                    itemsAvailable={maquinarioData}
                    valueExpr='name'
                    searchExpr='name'
                    inputValue={fabricante_pulverizador?.name}
                    label='Fabricante do pulverizador (*)'
                    labelMode='static'
                    stylingMode='underlined'
                    height={50}
                    searchMode='startswith'
                    typeData='maquinario'
                    onValueChanged={(e) => setFabricantePulverizador(e)}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <TextBox
                    value={modelo_pulverizador}
                    onValueChange={(e) => setMarcaPulverizador(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Modelo do pulverizador (*)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <NumberBox
                    value={
                      ano_pulverizador !== undefined
                        ? ano_pulverizador
                        : undefined
                    }
                    onValueChange={(e) => setAnoPulverizador(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Ano do pulverizador (*)'
                    width='100%'
                    height={50}
                    max={new Date().getFullYear()}
                  />
                </C.BoxInput>
              </C.GroupInputs2>

              <br />
              <br />
              <C.InfoSection>Colhedora</C.InfoSection>

              <C.GroupInputs2>
                <C.BoxInput>
                  <AutoCompleteCustom<Manufacturer>
                    itemsAvailable={maquinarioData}
                    valueExpr='name'
                    searchExpr='name'
                    inputValue={fabricante_colhedora?.name}
                    label='Fabricante da colhedora (*)'
                    labelMode='static'
                    stylingMode='underlined'
                    height={50}
                    searchMode='startswith'
                    typeData='maquinario'
                    onValueChanged={(e) => setFabricanteColhedora(e)}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <TextBox
                    value={modelo_colhedora}
                    onValueChange={(e) => setModeloColhedora(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Modelo da colhedora (*)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <NumberBox
                    value={
                      ano_colhedora !== undefined ? ano_colhedora : undefined
                    }
                    onValueChange={(e) => setAnoColhedora(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Ano da colhedora (*)'
                    width='100%'
                    height={50}
                    max={new Date().getFullYear()}
                  />
                </C.BoxInput>
              </C.GroupInputs2>
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>
          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate('/passo9')}
              title='VOLTAR'
              color='#2F80ED'
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmit}
              title='SALVAR E AVANÇAR'
              color='#27AE60'
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
          <br />
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
