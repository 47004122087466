import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiCheckCircle, BiSpreadsheet } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { useNavigate } from 'react-router-dom';

export const FormStep14 = () => {
  const navigate = useNavigate();

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Inscrição realizada com sucesso!' />
        </C.HeaderContentRight>
        <br />
        <C.SeeData>
          <Button
            onClick={() => navigate('/dados-inscricao')}
            title='Verificar inscrição'
            color='#27AE60'
            iconRight={<BiSpreadsheet fontSize={22} />}
          />
        </C.SeeData>
        <C.ContentRightForm>
          <C.BoxFinal>
            <BiCheckCircle fontSize={106} />
            <h3>
              Parabéns! Sua inscrição foi
              <br /> enviada com sucesso ;
            </h3>
          </C.BoxFinal>

          <C.Thanks>
            Obrigado por ter se inscrito no concurso GETAP. No período de
            colheita, o responsável pela audioria entrará em contato para que os
            detalhes sejam acertados
          </C.Thanks>

          {/* <C.BoxButtonSubmit>
                        <Button path='/status' title='ACOMPANHE O STATUS DA SUA INSCRIÇÃO' color='#27AE60' iconRight={<BiChevronRight fontSize={22} />} />
                    </C.BoxButtonSubmit> */}
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
