import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronRight, BiSearchAlt } from 'react-icons/bi';
import { Genre, UF } from '../../data/data';

import 'devextreme/dist/css/dx.light.css';
import { SelectBox, TextBox } from 'devextreme-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/auth';
import { Input } from '../../components/input/Input';
import { maskDate } from '../../helpers/helperDate';
import { Validator, EmailRule } from 'devextreme-react/validator';
import { splitName } from '../../utils/StringUtils';

export const CreateUser = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [nome, setNome] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [genero, setGenero] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [load, setLoad] = useState(false);

  const getCep = () => {
    if (cep.length === 8) {
      setLoad(true);
      setTimeout(() => {
        api.get(`https://viacep.com.br/ws/${cep}/json/`).then((res) => {
          setLoad(false);
          setLogradouro(res.data.logradouro);
          setBairro(res.data.bairro);
          setCidade(res.data.localidade);
          setEstado(res.data.uf);
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (cep) {
      getCep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cep]);

  const handleSaveUser = useCallback(async () => {
    console.log('entrei');

    if (
      nome === '' ||
      email === '' ||
      genero === '' ||
      phone1 === '' ||
      cep === '' ||
      cidade === '' ||
      estado === '' ||
      logradouro === '' ||
      numero === '' ||
      bairro === '' ||
      password === '' ||
      nascimento === ''
    ) {
      return toast.error('Preencha todos os campos obrigatórios!');
    }
    if (email !== confirmEmail) {
      return toast.error('Atenção! Emails são diferentes.');
    }

    if (confirmPassword !== password) {
      return toast.error('Atenção! As senha não são iguais.');
    }

    const splitDate = maskDate(nascimento).split('/');
    const date = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

    const { primeiroNome, sobrenome } = splitName(nome);

    await api
      .post('/users', {
        name: primeiroNome,
        last_name: sobrenome,
        email,
        birthday: date,
        gender: genero,
        phone1,
        phone2,
        zip_code: cep,
        city: cidade,
        uf: estado,
        address: logradouro,
        number: numero,
        complement: complemento,
        district: bairro,
        password,
      })
      .then((res) => {
        if (res.status === 201) {
          signIn({ email, password });
          navigate('/initial');
        }
      })
      .catch((error) => toast.error(error.response.data.message));
  }, [
    signIn,
    nome,
    email,
    nascimento,
    genero,
    phone1,
    phone2,
    cep,
    cidade,
    estado,
    logradouro,
    numero,
    complemento,
    bairro,
    password,
    confirmEmail,
    confirmPassword,
    navigate,
  ]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Cadastro de usuário' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <TextBox
                value={email}
                onValueChange={(e) => setEmail(e)}
                stylingMode='underlined'
                labelMode='static'
                label='E-mail (*)'
                placeholder='email@email.com'
                height={50}
              >
                <Validator>
                  <EmailRule message='Informe um e-mail  válido!' />
                </Validator>
              </TextBox>
              <br />
              <TextBox
                value={confirmEmail}
                onValueChange={(e) => setConfirmEmail(e)}
                stylingMode='underlined'
                labelMode='static'
                label='Confirmação de e-mail'
                placeholder='email@email.com'
                height={50}
              >
                <Validator>
                  <EmailRule message='Informe um e-mail  válido!' />
                </Validator>
              </TextBox>
              <br />
              <TextBox
                value={nome}
                onValueChange={(e) => setNome(e)}
                stylingMode='underlined'
                labelMode='static'
                label='Nome completo (*)'
                placeholder='Ex: José'
                height={50}
              />
              <br />
              <Input
                width='100%'
                style={{ marginBottom: '15px' }}
                label='Data de Nascimento (*)'
                type='text'
                value={maskDate(nascimento)}
                maxLength={10}
                onChange={(e) => setNascimento(e.target.value)}
              />
              <SelectBox
                items={Genre}
                onValueChanged={(e) => setGenero(e.value)}
                stylingMode='underlined'
                labelMode='static'
                label='Gênero (*)'
                placeholder='Selecione'
                height={50}
              />
              <br />
              <TextBox
                value={phone1}
                onValueChange={(e) => setPhone1(e)}
                stylingMode='underlined'
                labelMode='static'
                label='Telefone de contato 01 (*)'
                mask='(00) 00000-0000'
                height={50}
              />
              <br />
              <TextBox
                value={phone2}
                onValueChange={(e) => setPhone2(e)}
                stylingMode='underlined'
                labelMode='static'
                label='Telefone de contato 02'
                mask='(00) 00000-0000'
                height={50}
              />
              <br />
            </C.ContentRightFormBoxLeft>

            <C.ContentRightFormBoxRight>
              {load ? (
                <C.Load>
                  Procurando endereço... &nbsp;<span></span>
                </C.Load>
              ) : (
                <C.Search onClick={getCep}>
                  <BiSearchAlt />
                </C.Search>
              )}
              <TextBox
                value={cep}
                onValueChange={(e) => setCep(e)}
                stylingMode='underlined'
                labelMode='static'
                label='CEP (*)'
                height={50}
              />
              <br />
              <TextBox
                value={logradouro}
                onValueChange={(e) => setLogradouro(e)}
                stylingMode='underlined'
                labelMode='static'
                label='Endereço (*)'
                height={50}
              />
              <br />
              <C.GroupInputs style={{ marginBottom: 0 }}>
                <C.BoxInput>
                  <TextBox
                    value={numero}
                    onValueChange={(e) => setNumero(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Nº (*)'
                    height={50}
                  />
                  <br />
                </C.BoxInput>
                <TextBox
                  value={complemento}
                  onValueChange={(e) => setComplemento(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Complemento'
                  width='100%'
                  height={50}
                />
                <br />
              </C.GroupInputs>
              <TextBox
                value={bairro}
                onValueChange={(e) => setBairro(e)}
                stylingMode='underlined'
                labelMode='static'
                label='Bairro (*)'
                height={50}
              />
              <br />

              <C.GroupInputs>
                <C.BoxInput>
                  <TextBox
                    value={cidade}
                    onValueChange={(e) => setCidade(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Cidade (*)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
                <SelectBox
                  items={UF}
                  value={estado}
                  onValueChanged={(e) => setEstado(e.value)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='UF (*)'
                  width='100%'
                  height={50}
                />
              </C.GroupInputs>

              <TextBox
                mode='password'
                value={password}
                onValueChange={(e) => setPassword(e)}
                stylingMode='underlined'
                labelMode='static'
                label='Senha (*)'
                height={50}
              />
              <br />
              <TextBox
                mode='password'
                value={confirmPassword}
                onValueChange={(e) => setConfirmPassword(e)}
                stylingMode='underlined'
                labelMode='static'
                label='Confirmação de senha (*)'
                height={50}
              />
              <br />
            </C.ContentRightFormBoxRight>
          </C.BoxFields>

          <C.BoxButtonSubmit>
            <Button
              title='CADASTRAR'
              color='#27AE60'
              onClick={handleSaveUser}
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
