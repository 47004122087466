import {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { User } from '../store/types';
import { useAppStore } from '../store';

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
  confirm_password?: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  loading: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: any) => {
  const [loading, setLoading] = useState(false);
  const clearSubscription = useAppStore((store) => store.clearSubscription);
  const loadConfig = useAppStore((store) => store.loadConfig);
  const loadHybrids = useAppStore((store) => store.loadHybrids);
  const loadManufacturers = useAppStore((store) => store.loadManufacturers);
  const loadDefensives = useAppStore((store) => store.loadDefensives);
  const loadNutritionManufacturers = useAppStore(
    (store) => store.loadNutritionManufacturers
  );

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@GetapAPP:token');
    const user = localStorage.getItem('@GetapAPP:user');

    if (token && user) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signOut = useCallback(() => {
    localStorage.removeItem('@GetapAPP:token');
    localStorage.removeItem('@GetapAPP:user');
    localStorage.removeItem('@GetapAPP:subscription_id');

    clearSubscription();
    setData({} as AuthState);
  }, [clearSubscription]);

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    setLoading(true);
    try {
      const response = await api.post('/sessions', {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@GetapAPP:token', token);
      localStorage.setItem('@GetapAPP:user', JSON.stringify(user));
      localStorage.removeItem('@GetapAPP:subscription_id');

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      setData({ token, user });
      setLoading(false);
    } catch (e) {
      toast.error('E-mail/senha não conferem!');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const subscribe = api.registerInterceptTokenManager(signOut);

    return () => {
      subscribe();
    };
  }, [signOut]);

  useEffect(() => {
    loadConfig();
    loadHybrids();
    loadManufacturers();
    loadDefensives();
    loadNutritionManufacturers();
  }, [
    loadConfig,
    loadDefensives,
    loadHybrids,
    loadManufacturers,
    loadNutritionManufacturers,
  ]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
