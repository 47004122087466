import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '../../components/Tooltip';
import {
  HelpCalagem,
  HelpDataCalagem,
  HelpDataGessagem,
  HelpGessagem,
  SystemProduction,
  UnidadeMedida,
} from '../../data/data';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { useAppStore } from '../../store';

export const FormStep9 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);

  const navigate = useNavigate();
  const [calagem_frequency, setCalagemFrequency] = useState(
    subscription.property?.frequency_liming
  );
  const [calagem_dose, setCalagemDose] = useState(
    subscription.property?.dosage_liming
  );
  const [calagem_dose_un_medida, setCalagemDoseUnMedida] = useState(
    subscription.property?.unit_liming
  );

  const [gessagem_frequency, setGessagemFrequency] = useState(
    subscription.property?.frequency_gessagem
  );
  const [gessagem_dose, setGessagemDose] = useState(
    subscription.property?.dosage_gessagem
  );
  const [gessagem_dose_un_medida, setGessagemDoseUnMedida] = useState(
    subscription.property?.unit_gessagem
  );

  const [sistema_producao, setSistemaProducao] = useState(
    subscription.property?.production_system
  );
  const [populacao_almejada, setPopulacaoAlmejada] = useState(
    subscription.property?.target_population
  );

  const handleSubmit = useCallback(async () => {
    if (
      calagem_frequency === undefined ||
      gessagem_frequency === undefined ||
      sistema_producao === undefined ||
      gessagem_dose === undefined ||
      calagem_dose === undefined
    ) {
      return toast.error('Preencha os campos obrigatórios!');
    }

    api
      .patch('/subscription/add-info-soil', {
        subscription_id: subscription.id,
        current_step: 9,
        frequency_liming: calagem_frequency,
        dosage_liming: calagem_dose,
        unit_liming: calagem_dose_un_medida,
        frequency_gessagem: gessagem_frequency,
        dosage_gessagem: gessagem_dose,
        unit_gessagem: gessagem_dose_un_medida,
        production_system: sistema_producao,
        target_population: populacao_almejada,
      })
      .then((res) => {
        if (res.status === 200) {
          setSubscription(res.data);
          if (subscription.current_step < 9) {
            setStep(9);
          }
          toast.success('Dados salvos com sucesso!');
          navigate('/passo10');
        }
      })
      .catch(() => toast.error('Ops! Ocorreu um erro, tente novamente'));
  }, [
    navigate,
    setStep,
    subscription,
    calagem_frequency,
    calagem_dose,
    gessagem_frequency,
    gessagem_dose,
    populacao_almejada,
    setSubscription,
    sistema_producao,
    calagem_dose_un_medida,
    gessagem_dose_un_medida,
  ]);

  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');

  useEffect(() => {
    if (subscription_id !== null && subscription_id !== subscription.id) {
      loadSubscription(subscription_id);
    }
    setCalagemFrequency(subscription.property?.frequency_liming);
    setCalagemDose(subscription.property?.dosage_liming);
    setCalagemDoseUnMedida(subscription.property?.unit_liming || 'Kg/ha');
    setGessagemFrequency(subscription.property?.frequency_gessagem);
    setGessagemDose(subscription.property?.dosage_gessagem);
    setGessagemDoseUnMedida(subscription.property?.unit_gessagem || 'Kg/ha');

    setSistemaProducao(subscription.property?.production_system);
    setPopulacaoAlmejada(subscription.property?.target_population);
  }, [loadSubscription, subscription, subscription_id]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Informações da área inscrita: Solo' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.InfoSection>
                Calagem de solo{' '}
                <Tooltip
                  info={HelpDataCalagem}
                  icon={<AiFillQuestionCircle />}
                />{' '}
              </C.InfoSection>

              <C.GroupInputs>
                <C.BoxInput>
                  <div
                    style={{
                      position: 'absolute',
                      right: '30px',
                      zIndex: '1000',
                      color: '#2F80ED',
                    }}
                  >
                    <Tooltip
                      info={HelpCalagem}
                      icon={<AiFillQuestionCircle />}
                    />
                  </div>
                  <NumberBox
                    value={
                      calagem_frequency !== undefined
                        ? calagem_frequency
                        : undefined
                    }
                    onValueChange={setCalagemFrequency}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Frequência (a cada x anos) (*)'
                    width='100%'
                    height={50}
                    max={99}
                  />
                  <br />
                </C.BoxInput>
                <C.BoxInput>
                  <NumberBox
                    value={calagem_dose}
                    onValueChange={setCalagemDose}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Dose (*)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <SelectBox
                    value={calagem_dose_un_medida}
                    onValueChange={setCalagemDoseUnMedida}
                    items={UnidadeMedida}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Unidade de medida'
                    width='100%'
                    height={50}
                  />
                  <br />
                </C.BoxInput>
              </C.GroupInputs>

              <C.InfoSection>
                Gessagem{' '}
                <Tooltip
                  info={HelpDataGessagem}
                  icon={<AiFillQuestionCircle />}
                />{' '}
              </C.InfoSection>

              <C.GroupInputs>
                <C.BoxInput>
                  <div
                    style={{
                      position: 'absolute',
                      right: '30px',
                      zIndex: '1000',
                      color: '#2F80ED',
                    }}
                  >
                    <Tooltip
                      info={HelpGessagem}
                      icon={<AiFillQuestionCircle />}
                    />
                  </div>
                  <NumberBox
                    value={
                      gessagem_frequency !== undefined
                        ? gessagem_frequency
                        : undefined
                    }
                    onValueChange={setGessagemFrequency}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Frequência (a cada x anos) (*)'
                    width='100%'
                    height={50}
                    max={99}
                  />
                  <br />
                </C.BoxInput>
                <C.BoxInput>
                  <NumberBox
                    value={gessagem_dose}
                    onValueChange={setGessagemDose}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Dose (*)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
                <C.BoxInput>
                  <SelectBox
                    value={gessagem_dose_un_medida}
                    onValueChange={setGessagemDoseUnMedida}
                    items={UnidadeMedida}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Unidade de medida'
                    width='100%'
                    height={50}
                  />
                  <br />
                </C.BoxInput>
              </C.GroupInputs>
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>

          <C.HeaderContentRight>
            <Title title='Informações da área inscrita: Implantação da cultura' />
            <span>(*) Campos obrigatórios</span>
          </C.HeaderContentRight>
          <br />
          <br />
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.GroupInputs>
                <C.BoxInput>
                  <TextBox
                    value={subscription?.property?.hybrid.name}
                    readOnly
                    stylingMode='underlined'
                    labelMode='static'
                    label='Hibrido usado na área inscrita (*)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
                <SelectBox
                  value={sistema_producao}
                  onValueChange={(e) => setSistemaProducao(e)}
                  items={SystemProduction}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Sistema de produção (*)'
                  placeholder='Selecione'
                  width='100%'
                  height={50}
                />
              </C.GroupInputs>

              <C.GroupInputsEpecified>
                <C.BoxInput>
                  <NumberBox
                    value={
                      populacao_almejada === undefined
                        ? undefined
                        : Number(populacao_almejada)
                    }
                    onValueChange={setPopulacaoAlmejada}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Populacao almejada'
                    width='100%'
                    height={50}
                    max={999999}
                  />
                </C.BoxInput>
              </C.GroupInputsEpecified>
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>
          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate('/passo8')}
              title='VOLTAR'
              color='#2F80ED'
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmit}
              title='SALVAR E AVANÇAR'
              color='#27AE60'
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
          <br />
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
