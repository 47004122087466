import styled, { keyframes } from 'styled-components';

const spinner = keyframes`
    to {
        transform: rotate(360deg);
    }
    from {
        transform: rotate(0deg);
    }
`

export const ContainerLoad = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0, .2);

    h3 {
        color: #254D6B;
    }

    .loading {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 4px solid #fff;
        border-bottom-color: #254D6B;
        border-right-color: #254D6B;
        border-left-color: #254D6B;
        border-top-color: transparent;
        animation: ${spinner} 1s linear infinite;
    }
`;