import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight, BiPlusCircle } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { DataGrid, NumberBox, SelectBox, TextBox } from 'devextreme-react';
import {
  DataEstadioFenologico,
  HelpFungicida,
  HelpHerbicida,
  HelpInseticidaCigarrinha,
  HelpInseticidaLagarta,
  HelpNematicida,
  UnidadeMedidaFormulaAdubo,
} from '../../data/data';
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '../../components/Tooltip';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { Column } from 'devextreme-react/data-grid';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Modal } from '../../components/Modal';
import { useAppStore } from '../../store';
import { Protection, ProtectionProduct } from '../../store/types';

import AutoCompleteCustom from '../../components/AutoCompleteCustom';

export const FormStep12 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const loadListNutriCultive = useAppStore((store) => store.loadListProtection);
  const listNutriCultive = useAppStore((store) => store.listProtection);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const defensivosData = useAppStore((store) => store.defensives);
  const setStep = useAppStore((store) => store.setStep);

  const navigate = useNavigate();

  const [nome, setNome] = useState('');

  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [isPopupEditVisible, setPopupEditVisibility] = useState(false);
  const [isModalVisibel, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  const [idToUpdate, setIdToUpdate] = useState('');
  const [formula, setFormula] = useState('');
  const [dose, setDose] = useState('');
  const [dose_un_medida, setDoseUnMedida] = useState('');
  const [nomeComercial, setNomeComercial] = useState<
    ProtectionProduct | undefined
  >(undefined);
  const [fabricante, setFabricante] = useState('');
  const [estadioFenologico, setEstadioFenologico] = useState('');
  const [outros, setOutros] = useState('');

  const [qtd_herbi, setQtdHerbi] = useState(subscription.herbicide);
  const [qtd_lagarta, setQtdLagarta] = useState(
    subscription.insecticide_caterpillars
  );
  const [qtd_cigarra, setQtdCigarra] = useState(
    subscription.insecticide_cicadas
  );
  const [qtd_fungicida, setQtdFungicida] = useState(subscription.fungicide);
  const [qtd_biodefenciva, setQtdNematicida] = useState(
    subscription.biodefencive
  );

  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');

  const handleValueChangeNomeComercial = (e: ProtectionProduct) => {
    console.log('EVENTO', e, defensivosData);

    setNomeComercial(e);
    setFormula(e.active_ingredient!);
    setFabricante(e.manufacturer!);
  };

  useEffect(() => {
    if (subscription_id !== null && subscription_id !== subscription.id) {
      loadSubscription(subscription_id);
      loadListNutriCultive(subscription_id);
    }

    setQtdHerbi(subscription.herbicide);
    setQtdLagarta(subscription.insecticide_caterpillars);
    setQtdCigarra(subscription.insecticide_cicadas);
    setQtdFungicida(subscription.fungicide);
    setQtdNematicida(subscription.biodefencive);
  }, [loadSubscription, subscription, subscription_id, loadListNutriCultive]);

  const togglePopup = useCallback(
    (title: string, name: string) => {
      setIsModalVisible(true);
      setPopupVisibility(!isPopupVisible);
      setNome(name);

      setFormula('');
      setDose('');
      setDoseUnMedida('');
      setNomeComercial(undefined);
      setFabricante('');
      setEstadioFenologico('');
      setOutros('');
    },
    [isPopupVisible, setPopupVisibility]
  );

  const togglePopupEdit = useCallback(
    (data: any) => {
      setIsModalEditVisible(true);
      setPopupEditVisibility(!isPopupEditVisible);
      console.log(data.data);

      setNome(data.data.protection_type);

      setIdToUpdate(data.data.id);
      setFormula(data.data.product_protection.active_ingredient);
      setDose(data.data.dose);
      setDoseUnMedida(data.data.dose_un_medida || 'Kg/ha');
      setNomeComercial(data.data.product_protection);
      setFabricante(data.data.product_protection.manufacturer);
      setEstadioFenologico(data.data.vegetative_stage);
      setOutros(data.data.nome_outros);
    },
    [isPopupEditVisible]
  );

  const loadData = useCallback(async () => {
    await loadListNutriCultive(subscription.id);
  }, [subscription, loadListNutriCultive]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const nutri = useMemo(() => {
    console.log(listNutriCultive);
    const HERBICIDA = listNutriCultive.reduce(
      (acc: Protection[], item: Protection) => {
        return item.protection_type === 'HERBICIDA'
          ? (acc = [...acc, item])
          : acc;
      },
      [] as Protection[]
    );

    const LAGARTAS = listNutriCultive.reduce(
      (acc: Protection[], item: Protection) => {
        return item.protection_type === 'INSETICIDA_LAGARTA'
          ? (acc = [...acc, item])
          : acc;
      },
      [] as Protection[]
    );

    const CIGARRAS = listNutriCultive.reduce(
      (acc: Protection[], item: Protection) => {
        return item.protection_type === 'INSETICIDA_CIGARRA'
          ? (acc = [...acc, item])
          : acc;
      },
      [] as Protection[]
    );

    const FUNGICIDA = listNutriCultive.reduce(
      (acc: Protection[], item: Protection) => {
        return item.protection_type === 'FUNGICIDA'
          ? (acc = [...acc, item])
          : acc;
      },
      [] as Protection[]
    );

    const BIODEFENSIVO = listNutriCultive.reduce(
      (acc: Protection[], item: Protection) => {
        return item.protection_type === 'BIODEFENCIVO'
          ? (acc = [...acc, item])
          : acc;
      },
      [] as Protection[]
    );

    return {
      HERBICIDA,
      LAGARTAS,
      CIGARRAS,
      FUNGICIDA,
      BIODEFENSIVO,
    };
  }, [listNutriCultive]);

  const cleanData = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
    setNome('');
    setIdToUpdate('');
    setNome('');
    setFormula('');
    setDose('');
    setDoseUnMedida('');
    setNomeComercial(undefined);
    setFabricante('');
    setEstadioFenologico('');
    setOutros('');
    loadData();
  }, [isPopupVisible, loadData]);

  const handleSubmitInfo = useCallback(async () => {
    if (
      formula === '' ||
      dose === '' ||
      nomeComercial?.id === null ||
      estadioFenologico === '' ||
      dose_un_medida === ''
    ) {
      return toast.error('Preencha todos os campos obrigratorios!');
    }
    if (idToUpdate !== '') {
      api
        .post('/subscription/create-protection', {
          id: idToUpdate,
          subscription_id: subscription.id,
          protection_type: nome,
          dose,
          unit_measurement: dose_un_medida,
          product_protection_id: nomeComercial?.id,
          vegetative_stage: estadioFenologico,
          name_others: outros,
        })
        .then((res) => {
          if (res.status === 200) {
            loadData();

            setPopupEditVisibility(false);
            setPopupVisibility(false);
            setIsModalVisible(false);
            setIsModalEditVisible(false);
            toast.success('Registro salvo com sucesso!');
          }
          cleanData();
        })
        .catch((e) => toast.error(`${e.response.data.message}`));
    } else {
      api
        .post('/subscription/create-protection', {
          subscription_id: subscription.id,
          protection_type: nome,
          dose,
          unit_measurement: dose_un_medida,
          product_protection_id: nomeComercial?.id,
          vegetative_stage: estadioFenologico,
          name_others: outros,
        })
        .then((res) => {
          if (res.status === 200) {
            loadData();
            setPopupEditVisibility(false);
            setPopupVisibility(false);
            setIsModalVisible(false);
            toast.success('Registro salvo com sucesso!');
          }
        })
        .catch((e) => toast.error(`${e.response.data.message}`))
        .finally(() => {
          cleanData();
        });
    }
  }, [
    subscription,
    setPopupVisibility,
    loadData,
    nome,
    formula,
    dose,
    dose_un_medida,
    nomeComercial,
    idToUpdate,
    estadioFenologico,
    outros,
    cleanData,
  ]);

  const [screen, setScreen] = useState(0);

  const handleSubmitNextStep = useCallback(() => {
    if (!subscription.id) {
      return toast.error('Ainda não foi criado um manejo!');
    }

    if (
      qtd_herbi === undefined ||
      qtd_lagarta === undefined ||
      qtd_cigarra === undefined ||
      qtd_fungicida === undefined ||
      qtd_biodefenciva === undefined
    ) {
      return toast.error('Número de aplicações é obrigatório!');
    }

    if (qtd_herbi > 0 && nutri.HERBICIDA.length === 0) {
      return toast.error('Adicione pelo menos uma aplicação Herbicida!');
    }
    if (qtd_lagarta > 0 && nutri.LAGARTAS.length === 0) {
      return toast.error(
        'Adicione pelo menos uma aplicação Inseticida(Lagartas)!'
      );
    }
    if (qtd_cigarra > 0 && nutri.CIGARRAS.length === 0) {
      return toast.error(
        'Adicione pelo menos uma aplicação Inseticida(Cigarrinhas)!'
      );
    }
    if (qtd_fungicida > 0 && nutri.FUNGICIDA.length === 0) {
      return toast.error('Adicione pelo menos uma aplicação Fungicida!');
    }

    if (
      qtd_biodefenciva > 0 &&
      nutri.BIODEFENSIVO.length !== qtd_biodefenciva
    ) {
      return toast.error(
        `Adicione ${
          qtd_biodefenciva > 1 ? 'todas as apliacações' : 'a aplicação'
        } de Biodefensivo que declarou!`
      );
    }

    api
      .patch(`/subscription/add-protection-quantity`, {
        subscription_id: subscription.id,
        step: 12,
        herbicide: qtd_herbi,
        insecticide_caterpillars: qtd_lagarta,
        insecticide_cicadas: qtd_cigarra,
        fungicide: qtd_fungicida,
        biodefencive: qtd_biodefenciva,
      })
      .then((res) => {
        setSubscription(res.data);
        if (subscription.current_step < 12) {
          setStep(12);
        }
        toast.success('Dados salvos com sucesso!');
        navigate('/passo13');
      })
      .catch((err) => {
        return toast.error(err.response.data.message);
      });
  }, [
    navigate,
    setStep,
    subscription,
    setSubscription,
    qtd_cigarra,
    qtd_fungicida,
    qtd_herbi,
    qtd_lagarta,
    qtd_biodefenciva,
    nutri,
  ]);

  useEffect(() => {
    setScreen(window.screen.width);
  }, [screen]);

  const openModalDelete = (id: string) => {
    setIdToDelete(id);
    setIsModalDeleteVisible(true);
  };

  const handleDeleteRegister = () => {
    api
      .delete(`/subscription/protection/${idToDelete}`)
      .then((res) => {
        loadData();
        toast.success('Registro excluído com sucesso');
        setIsModalDeleteVisible(false);
      })
      .catch((e) => toast.error(`${e.response.data.message}`))
      .finally(() => {
        setPopupVisibility(!isPopupVisible);
        setNome('');
      });
  };

  const handleButtonsActions = (data: any) => {
    return (
      <C.BoxUpdateDelete>
        <FaEdit
          cursor={'pointer'}
          onClick={() => togglePopupEdit(data)}
          color={'#2F80ED'}
        />
        <FaTrash
          cursor={'pointer'}
          onClick={() => openModalDelete(data.data.id)}
          color={'#e73838'}
        />
      </C.BoxUpdateDelete>
    );
  };

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Informações da área inscrita: Proteção de cultivos' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>
        <br />
        <C.ContentRightForm>
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.BoxButton>
                <C.Label>
                  Herbicida{' '}
                  <span style={{ color: '#2F80ED' }}>
                    <Tooltip
                      info={HelpHerbicida}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_herbi !== undefined && qtd_herbi > 0 && (
                  <C.BtnAdd
                    onClick={() => togglePopup('Herbicida', 'HERBICIDA')}
                    style={{ marginRight: '20px' }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={'#333'} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_herbi}
                  onValueChange={(e) => setQtdHerbi(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Número de aplicações (*)'
                  width='200px'
                  height={50}
                  style={{ marginLeft: '20px' }}
                />
              </C.BoxButton>

              {isModalVisibel && (
                <Modal
                  titleHeader='Preencha os campos abaixo'
                  onClose={() => setIsModalVisible(false)}
                  mxheight='650px'
                >
                  <C.GroupInputs>
                    <C.BoxInput>
                      <AutoCompleteCustom<ProtectionProduct>
                        itemsAvailable={defensivosData}
                        valueExpr='name'
                        searchExpr='name'
                        inputValue={nomeComercial?.name}
                        label='Nome Comercial (*)'
                        labelMode='static'
                        stylingMode='underlined'
                        height={50}
                        searchMode='startswith'
                        typeData='defensivo'
                        onValueChanged={(e) =>
                          handleValueChangeNomeComercial(e)
                        }
                      />
                    </C.BoxInput>

                    <C.BoxInput>
                      <TextBox
                        value={formula}
                        onValueChange={setFormula}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Ingrediente Ativo (*)'
                        width='100%'
                        readOnly={true}
                        height={50}
                        disabled={true}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <SelectBox
                        value={estadioFenologico}
                        onValueChange={setEstadioFenologico}
                        items={DataEstadioFenologico}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Estádio Vegetativo (*)'
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    {estadioFenologico === 'Outros' && (
                      <C.BoxInput>
                        <TextBox
                          value={outros}
                          onValueChange={setOutros}
                          stylingMode='underlined'
                          labelMode='static'
                          label='Digite o estádio fenológico'
                          width='100%'
                          height={50}
                          style={{ marginRight: '20px' }}
                        />
                      </C.BoxInput>
                    )}
                    <C.BoxInput>
                      <TextBox
                        value={fabricante}
                        onValueChange={setFabricante}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Fabricante'
                        readOnly={true}
                        width='100%'
                        height={50}
                        disabled={true}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <NumberBox
                        defaultValue={dose}
                        onValueChange={setDose}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Dose (*)'
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <SelectBox
                        value={dose_un_medida}
                        onValueChange={setDoseUnMedida}
                        items={UnidadeMedidaFormulaAdubo}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Unidade de medida da dose(*)'
                        width='100%'
                        height={50}
                      />
                    </C.BoxInput>
                  </C.GroupInputs>
                  <br />
                  <C.BoxButtonSubmit>
                    <Button
                      onClick={handleSubmitInfo}
                      title='SALVAR'
                      color='#27AE60'
                      style={{ marginLeft: 'auto' }}
                      iconRight={<BiChevronRight fontSize={22} />}
                    />
                  </C.BoxButtonSubmit>
                </Modal>
              )}

              {isModalEditVisible && (
                <Modal
                  titleHeader='Preencha os campos abaixo'
                  onClose={() => setIsModalEditVisible(false)}
                  mxheight='650px'
                >
                  <C.GroupInputs>
                    <C.BoxInput>
                      <AutoCompleteCustom<ProtectionProduct>
                        itemsAvailable={defensivosData}
                        valueExpr='name'
                        searchExpr='name'
                        inputValue={nomeComercial?.name}
                        label='Nome Comercial (*)'
                        labelMode='static'
                        stylingMode='underlined'
                        height={50}
                        searchMode='startswith'
                        typeData='defensivo'
                        onValueChanged={handleValueChangeNomeComercial}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <TextBox
                        value={formula}
                        onValueChange={setFormula}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Ingrediente Ativo (*)'
                        width='100%'
                        height={50}
                        disabled={true}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <SelectBox
                        value={estadioFenologico}
                        onValueChange={setEstadioFenologico}
                        items={DataEstadioFenologico}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Estádio Vegetativo (*)'
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    {estadioFenologico === 'Outros' && (
                      <C.BoxInput>
                        <TextBox
                          value={outros}
                          onValueChange={setOutros}
                          stylingMode='underlined'
                          labelMode='static'
                          label='Digite o estádio fenológico'
                          width='100%'
                          height={50}
                          style={{ marginRight: '20px' }}
                        />
                      </C.BoxInput>
                    )}
                    <C.BoxInput>
                      <TextBox
                        value={fabricante}
                        onValueChange={setFabricante}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Fabricante'
                        width='100%'
                        height={50}
                        disabled={true}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <NumberBox
                        defaultValue={dose}
                        onValueChange={setDose}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Dose (*)'
                        width='100%'
                        height={50}
                        style={{ marginRight: '20px' }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <SelectBox
                        value={dose_un_medida}
                        onValueChange={setDoseUnMedida}
                        items={UnidadeMedidaFormulaAdubo}
                        stylingMode='underlined'
                        labelMode='static'
                        label='Unidade de medida da dose'
                        width='100%'
                        height={50}
                      />
                    </C.BoxInput>
                  </C.GroupInputs>
                  <br />
                  <C.BoxButtonSubmit>
                    <Button
                      onClick={handleSubmitInfo}
                      title='ATUALIZAR'
                      color='#27AE60'
                      style={{ marginLeft: 'auto' }}
                      iconRight={<BiChevronRight fontSize={22} />}
                    />
                  </C.BoxButtonSubmit>
                </Modal>
              )}

              {isModalDeleteVisible && (
                <Modal
                  titleHeader='Excluier registro'
                  onClose={() => setIsModalDeleteVisible(false)}
                  mxheight='220px'
                >
                  <h2 style={{ margin: '0 auto', color: '#444' }}>
                    Deseja excluir este registro?
                  </h2>
                  <br />

                  <C.BoxButtonSubmit>
                    <Button
                      onClick={handleDeleteRegister}
                      title='Excluir'
                      color='#27AE60'
                      style={{ marginLeft: 'auto' }}
                    />
                  </C.BoxButtonSubmit>
                </Modal>
              )}

              {nutri?.HERBICIDA !== undefined &&
                nutri?.HERBICIDA.length > 0 && (
                  <DataGrid
                    dataSource={nutri?.HERBICIDA}
                    focusedRowEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr={'id'}
                  >
                    <Column
                      caption={'Nome Comercial'}
                      dataField={'product_protection.name'}
                    />
                    <Column
                      caption={'Ingrediente Ativo'}
                      dataField={'product_protection.active_ingredient'}
                    />
                    <Column
                      caption={'Estádio Vegetativo'}
                      dataField={'vegetative_stage'}
                    />
                    <Column
                      caption={'Fabricante'}
                      dataField={'product_protection.manufacturer'}
                    />
                    <Column caption={'Dose'} dataField={'dose'} />

                    <Column
                      caption={'#'}
                      alignment={'center'}
                      cellRender={handleButtonsActions}
                    />
                  </DataGrid>
                )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Inseticida (lagartas){' '}
                  <span style={{ color: '#2F80ED' }}>
                    <Tooltip
                      info={HelpInseticidaLagarta}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_lagarta !== undefined && qtd_lagarta > 0 && (
                  <C.BtnAdd
                    onClick={() =>
                      togglePopup('Inseticida (lagartas)', 'INSETICIDA_LAGARTA')
                    }
                    style={{ marginRight: '20px' }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={'#333'} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_lagarta}
                  onValueChange={(e) => setQtdLagarta(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Número de aplicações (*)'
                  width='200px'
                  height={50}
                  style={{ marginLeft: '20px' }}
                />
              </C.BoxButton>

              {nutri?.LAGARTAS !== undefined && nutri?.LAGARTAS.length > 0 && (
                <DataGrid
                  dataSource={nutri?.LAGARTAS}
                  focusedRowEnabled={false}
                  hoverStateEnabled={true}
                  keyExpr={'id'}
                >
                  <Column
                    caption={'Nome Comercial'}
                    dataField={'product_protection.name'}
                  />
                  <Column
                    caption={'Ingrediente Ativo'}
                    dataField={'product_protection.active_ingredient'}
                  />
                  <Column
                    caption={'Estádio Vegetativo'}
                    dataField={'vegetative_stage'}
                  />
                  <Column
                    caption={'Fabricante'}
                    dataField={'product_protection.manufacturer'}
                  />
                  <Column caption={'Dose'} dataField={'dose'} />

                  <Column
                    caption={'#'}
                    alignment={'center'}
                    cellRender={handleButtonsActions}
                  />
                </DataGrid>
              )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Inseticida (cigarrinhas){' '}
                  <span style={{ color: '#2F80ED' }}>
                    <Tooltip
                      info={HelpInseticidaCigarrinha}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_cigarra !== undefined && qtd_cigarra > 0 && (
                  <C.BtnAdd
                    onClick={() =>
                      togglePopup(
                        'Inseticida (cigarrinhas)',
                        'INSETICIDA_CIGARRA'
                      )
                    }
                    style={{ marginRight: '20px' }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={'#333'} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_cigarra}
                  onValueChange={(e) => setQtdCigarra(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Número de aplicações (*)'
                  width='200px'
                  height={50}
                  style={{ marginLeft: '20px' }}
                />
              </C.BoxButton>

              {nutri?.CIGARRAS !== undefined && nutri?.CIGARRAS.length > 0 && (
                <DataGrid
                  dataSource={nutri?.CIGARRAS}
                  focusedRowEnabled={false}
                  hoverStateEnabled={true}
                  keyExpr={'id'}
                >
                  <Column
                    caption={'Nome Comercial'}
                    dataField={'product_protection.name'}
                  />
                  <Column
                    caption={'Ingrediente Ativo'}
                    dataField={'product_protection.active_ingredient'}
                  />
                  <Column
                    caption={'Estádio Vegetativo'}
                    dataField={'vegetative_stage'}
                  />
                  <Column
                    caption={'Fabricante'}
                    dataField={'product_protection.manufacturer'}
                  />
                  <Column caption={'Dose'} dataField={'dose'} />

                  <Column
                    caption={'#'}
                    alignment={'center'}
                    cellRender={handleButtonsActions}
                  />
                </DataGrid>
              )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Fungicida{' '}
                  <span style={{ color: '#2F80ED' }}>
                    <Tooltip
                      info={HelpFungicida}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_fungicida !== undefined && qtd_fungicida > 0 && (
                  <C.BtnAdd
                    onClick={() => togglePopup('Fungicida', 'FUNGICIDA')}
                    style={{ marginRight: '20px' }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={'#333'} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_fungicida}
                  onValueChange={(e) => setQtdFungicida(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Número de aplicações (*)'
                  width='200px'
                  height={50}
                  style={{ marginLeft: '20px' }}
                />
              </C.BoxButton>

              {nutri?.FUNGICIDA !== undefined &&
                nutri?.FUNGICIDA.length > 0 && (
                  <DataGrid
                    dataSource={nutri?.FUNGICIDA}
                    focusedRowEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr={'id'}
                  >
                    <Column
                      caption={'Nome Comercial'}
                      dataField={'product_protection.name'}
                    />
                    <Column
                      caption={'Ingrediente Ativo'}
                      dataField={'product_protection.active_ingredient'}
                    />
                    <Column
                      caption={'Estádio Vegetativo'}
                      dataField={'vegetative_stage'}
                    />
                    <Column
                      caption={'Fabricante'}
                      dataField={'product_protection.manufacturer'}
                    />
                    <Column caption={'Dose'} dataField={'dose'} />

                    <Column
                      caption={'#'}
                      alignment={'center'}
                      cellRender={handleButtonsActions}
                    />
                  </DataGrid>
                )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Biodefensivo{' '}
                  <span style={{ color: '#2F80ED' }}>
                    <Tooltip
                      info={HelpNematicida}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_biodefenciva !== undefined && qtd_biodefenciva > 0 && (
                  <C.BtnAdd
                    onClick={() => togglePopup('Biodefensivo', 'BIODEFENCIVO')}
                    style={{ marginRight: '20px' }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={'#333'} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_biodefenciva}
                  onValueChange={(e) => setQtdNematicida(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Número de aplicações (*)'
                  width='200px'
                  height={50}
                  style={{ marginLeft: '20px' }}
                />
              </C.BoxButton>

              {nutri?.BIODEFENSIVO !== undefined &&
                nutri?.BIODEFENSIVO.length > 0 && (
                  <DataGrid
                    dataSource={nutri?.BIODEFENSIVO}
                    focusedRowEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr={'id'}
                  >
                    <Column
                      caption={'Nome Comercial'}
                      dataField={'product_protection.name'}
                    />
                    <Column
                      caption={'Ingrediente Ativo'}
                      dataField={'product_protection.active_ingredient'}
                    />
                    <Column
                      caption={'Estádio Vegetativo'}
                      dataField={'vegetative_stage'}
                    />
                    <Column
                      caption={'Fabricante'}
                      dataField={'product_protection.manufacturer'}
                    />
                    <Column caption={'Dose'} dataField={'dose'} />

                    <Column
                      caption={'#'}
                      alignment={'center'}
                      cellRender={handleButtonsActions}
                    />
                  </DataGrid>
                )}
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>

          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate('/passo11')}
              title='VOLTAR'
              color='#2F80ED'
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmitNextStep}
              title='SALVAR E AVANÇAR'
              color='#27AE60'
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
