import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { SelectBox, TextBox, LoadIndicator } from 'devextreme-react';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppStore } from '../../store';
import { UF } from '../../data/data';
import municipiosData from '../../data/municipios.json';
import { cpf } from 'cpf-cnpj-validator';
import {
  removeSpacesAndSpecialChars,
  validateEmail,
} from '../../utils/StringUtils';
import { Validator, EmailRule } from 'devextreme-react/validator';
import { IMunicipio } from '../../components/AutoCompleteCustom';
import AutoCompleteString from '../../components/AutoCompleteString';

export const FormStep2 = () => {
  const navigate = useNavigate();
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const isLoading = useAppStore((store) => store.isLoading);
  const [loadingRtv, setLoadingRtv] = useState(false);

  const [name_creator_sub, setNameCreatorSub] = useState(
    subscription.name_creator_sub
  );

  const [rtv_phone, setRtvPhone] = useState(
    subscription?.tsrepresentative?.phone
  );
  const [rtv_email, setRtvEmail] = useState(
    subscription?.tsrepresentative?.email
  );
  const [rtv_document, setRtvDocument] = useState(
    subscription?.tsrepresentative?.document
  );
  const [rtv_name, setRtvName] = useState(subscription?.tsrepresentative?.name);
  const [rtv_id, setRtvId] = useState(subscription?.tsr_id);

  const [rtv_uf, setRtvUF] = useState(subscription?.tsrepresentative?.uf);
  const [rtv_city, setRtvCity] = useState(subscription?.tsrepresentative?.city);

  const [municipiosPopup, setMunicipiosPopup] = useState(municipiosData);

  //fields popup
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);
  const config = useAppStore((store) => store.config);
  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');

  const updateDatasource = (e: string) => {
    setMunicipiosPopup(municipiosData.filter((i) => i['UF'] === e));
  };

  async function checkRTV(document: string) {
    setLoadingRtv(true);
    try {
      const { data } = await api.post('/subscription/check-rtv', { document });
      setRtvPhone(data.phone);
      setRtvEmail(data.email);
      setRtvName(data.name);
      setRtvId(data.id);
      setRtvUF(data.uf);
      setRtvCity(data.city);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingRtv(false);
    }
  }

  useEffect(() => {
    if (subscription_id !== null && subscription_id !== subscription.id) {
      loadSubscription(subscription_id);
    }
    setNameCreatorSub(subscription.name_creator_sub);
    setRtvId(subscription?.tsr_id);
    setRtvPhone(subscription?.tsrepresentative?.phone);
    setRtvEmail(subscription?.tsrepresentative?.email);
    setRtvDocument(subscription?.tsrepresentative?.document);
    setRtvName(subscription?.tsrepresentative?.name);
    setRtvUF(subscription?.tsrepresentative?.uf);
    setRtvCity(subscription?.tsrepresentative?.city);
  }, [subscription_id, subscription, loadSubscription]);

  const handleSave = useCallback(async () => {
    if (
      name_creator_sub === '' ||
      rtv_phone === '' ||
      rtv_email === '' ||
      rtv_document === '' ||
      rtv_name === '' ||
      rtv_uf === '' ||
      rtv_city === ''
    ) {
      return toast.error('Atenção! preencha todos os campos.');
    }

    if (!validateEmail(rtv_email)) {
      return toast.error('Atenção! Preencha um email válido.');
    }

    const cpfPure = removeSpacesAndSpecialChars(rtv_document!);

    if (!cpf.isValid(cpfPure || '')) {
      return toast.error('Preencha um CPF do RTV válido!');
    }

    api
      .post('/subscription/', {
        subscription_id: !!subscription.id ? subscription.id : null,
        name_creator_sub: name_creator_sub,
        tsr_id: !!subscription.tsr_id ? subscription.tsr_id : rtv_id,
        name: rtv_name,
        document: cpfPure,
        email: rtv_email,
        phone: rtv_phone,
        city: rtv_city,
        uf: rtv_uf,
        haverst: config.safra,
        current_step: 2,
      })
      .then((res) => {
        if (res.status === 201) {
          setSubscription(res.data);
          if (subscription.current_step < 2) {
            setStep(2);
          }
          toast.success('Salvo com sucesso');
          navigate('/passo3');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      });
  }, [
    name_creator_sub,
    rtv_name,
    rtv_document,
    rtv_email,
    rtv_phone,
    rtv_city,
    rtv_uf,
    rtv_id,
    navigate,
    subscription,
    setSubscription,
    config,
    setStep,
  ]);

  useEffect(() => {
    if (rtv_document !== undefined && rtv_document.length === 11) {
      checkRTV(rtv_document);
    }
  }, [rtv_document]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Dados pessoais e de propriedade' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>

        {isLoading && (
          <C.ContentRightForm>
            <div
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Title title='Carregando...' />
            </div>
          </C.ContentRightForm>
        )}

        {!isLoading && (
          <C.ContentRightForm>
            <C.BoxFields>
              <C.ContentRightFormBoxLeft>
                <TextBox
                  value={config.safra}
                  stylingMode='underlined'
                  labelMode='static'
                  disabled
                  label='Safra (*)'
                  height={50}
                />
                <br />
                <TextBox
                  value={name_creator_sub}
                  onValueChange={(e) => setNameCreatorSub(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Nome completo de quem está fazendo a inscrição (*)'
                  height={50}
                />
                <br />
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                  }}
                >
                  <TextBox
                    value={rtv_document}
                    onValueChange={(e) => setRtvDocument(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='CPF do RTV (*)'
                    height={50}
                    width='100%'
                    maxLength={13}
                    mask='000.000.000-00'
                  />
                  {loadingRtv && (
                    <div
                      style={{
                        position: 'absolute',
                        left: 140,
                        top: 10,
                      }}
                    >
                      <LoadIndicator height={30} width={30} />
                    </div>
                  )}
                </div>

                <br />
                <TextBox
                  value={rtv_name}
                  onValueChange={(e) => setRtvName(e)}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Nome do RTV (*)'
                  height={50}
                />
                <br />
                <C.GroupInputs>
                  <TextBox
                    value={rtv_email}
                    onValueChange={(e) => setRtvEmail(e)}
                    stylingMode='underlined'
                    labelMode='static'
                    label='E-mail do RTV (*)'
                    height={50}
                    width='100%'
                  >
                    <Validator>
                      <EmailRule message='Informe um e-mail  válido!' />
                    </Validator>
                  </TextBox>
                  <br />
                  <TextBox
                    value={rtv_phone}
                    onValueChange={(e) => setRtvPhone(e)}
                    mask='(00) 0 0000-0000'
                    stylingMode='underlined'
                    labelMode='static'
                    label='Contato do RTV (*)'
                    height={50}
                    width='100%'
                  />
                  <br />
                </C.GroupInputs>
                <C.GroupInputs>
                  <C.BoxInput>
                    <SelectBox
                      items={UF}
                      value={rtv_uf}
                      onValueChange={(e) => {
                        setRtvUF(e);
                        updateDatasource(e);
                        setRtvCity('');
                      }}
                      stylingMode='underlined'
                      labelMode='static'
                      label='UF residência do RTV (*)'
                      width='100%'
                      height={50}
                      style={{ marginRight: '20px', color: '#444' }}
                    />
                  </C.BoxInput>
                  <C.BoxInput>
                    <AutoCompleteString<IMunicipio>
                      itemsAvailable={municipiosPopup}
                      valueExpr='MUN'
                      searchExpr='MUN'
                      inputValue={rtv_city}
                      label='Município residëncia do RTV  (*)'
                      labelMode='static'
                      stylingMode='underlined'
                      height={50}
                      searchMode='startswith'
                      typeData='municipio'
                      onValueChanged={(e) => setRtvCity(e)}
                    />
                  </C.BoxInput>
                </C.GroupInputs>
              </C.ContentRightFormBoxLeft>
            </C.BoxFields>

            <C.BoxButtonSubmit>
              <Button
                type='button'
                onClick={() => {
                  setStep(0);
                  navigate(`/initial`);
                }}
                title='VOLTAR'
                color='#2F80ED'
                iconLeft={<BiChevronLeft fontSize={22} />}
              />
              <Button
                type='button'
                onClick={handleSave}
                title='SALVAR E AVANÇAR'
                color='#27AE60'
                iconRight={<BiChevronRight fontSize={22} />}
              />
            </C.BoxButtonSubmit>
          </C.ContentRightForm>
        )}
      </C.ContentRight>
      {/* )} */}
    </C.Container>
  );
};
