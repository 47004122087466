export function removeSpacesAndSpecialChars(str: string) {
  // Remove spaces and special characters using regular expressions
  return str
    .replace(/[\s~`!@#$%^&*(){}\];:"'<,.>?\\|_+=-]/g, '')
    .replaceAll('/', '');
}

export function validateEmail(email: string | undefined) {
  if (email === undefined || email === null) {
    return false;
  }
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function checkIsOnlyNumbers(value: string | undefined) {
  if (value === undefined || value === null) {
    return false;
  }
  const regex = /\D/;
  return regex.test(value);
}

export function splitName(nomeCompleto: string) {
  const partes = nomeCompleto.trim().split(' ');
  const primeiroNome = partes.shift(); // Pega o primeiro nome
  const sobrenome = partes.join(' '); // Junta o restante como sobrenome

  return { primeiroNome, sobrenome };
}
