import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiCheckCircle, BiChevronRight, BiCreditCard } from 'react-icons/bi';
import { RiCoupon2Line } from 'react-icons/ri';

import Mpago from '../../assets/png/mercado-pago-logo.png';
import Pix from '../../assets/png/pix.png';

import { CheckBox } from 'devextreme-react';
import { useMercadopago } from 'react-sdk-mercadopago/lib';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from '../../components/input/Input';
import { useAppStore } from '../../store';

interface IResPix {
  type: string;
  qr_code_base64: string;
  qr_code: string;
  ticket_url: string;
  transaction_id: number;
}

const PKMP = 'APP_USR-9fb436d6-2a02-420a-98ff-2e17883e0f4e';

export const FormStep6 = () => {
  const navigate = useNavigate();
  const [cupom, setCupom] = useState(false);
  const [payWithCreditCard, setPayWithCreditCard] = useState(true);
  const [payWithPix, setPayWithPix] = useState(false);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('CPF');
  const [number, setNumber] = useState('');
  const [code, setCode] = useState('');
  const [time, setTime] = useState(500);
  const payment_method_id = 'pix';
  const amout = 6000;
  const descri = 'Inscrição Getap';
  const [resPix, setResPix] = useState<IResPix>({} as IResPix);

  const subscription = useAppStore((store) => store.subscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);
  const config = useAppStore((store) => store.config);

  const mercadopago = useMercadopago.v2(PKMP, { locale: 'pt-BR' });

  const cardForm = mercadopago?.cardForm({
    amount: `${amout}`,
    iframe: true,
    form: {
      id: 'form-checkout',
      cardNumber: {
        id: 'form-checkout__cardNumber',
        placeholder: 'Número do cartão',
      },
      expirationDate: {
        id: 'form-checkout__expirationDate',
        placeholder: 'MM/YY',
      },
      securityCode: {
        id: 'form-checkout__securityCode',
        placeholder: 'Código de segurança',
      },
      cardholderName: {
        id: 'form-checkout__cardholderName',
        placeholder: 'Titular do cartão',
      },
      issuer: {
        id: 'form-checkout__issuer',
        placeholder: 'Banco emissor',
      },
      installments: {
        id: 'form-checkout__installments',
        placeholder: 'Parcelas',
      },
      identificationType: {
        id: 'form-checkout__identificationType',
        placeholder: 'Tipo de documento',
      },
      identificationNumber: {
        id: '2',
        placeholder: 'Número do documento',
      },
      cardholderEmail: {
        id: 'form-checkout__cardholderEmail',
        placeholder: 'E-mail',
      },
    },
    callbacks: {
      onFormMounted: (error: any) => {
        if (error) return console.warn('Form Mounted handling error: ', error);
        console.log('Form mounted');
      },
      onSubmit: (event: any) => {
        event.preventDefault();

        const {
          paymentMethodId: payment_method_id,
          issuerId: issuer_id,
          cardholderEmail: email,
          amount,
          token,
          installments,
          identificationNumber,
          identificationType,
        } = cardForm.getCardFormData();

        fetch('https://api.getap.agr.br/payments/createcard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            subscription_id: subscription.id,
            token,
            issuer_id,
            payment_method_id,
            transaction_amount: Number(amount),
            installments: Number(installments),
            description: 'Pagamento inscrição GetApp',
            email,
            type: identificationType,
            number: identificationNumber,
          }),
        })
          .then((res) => {
            res.json().then((json) => {
              if (json.status === 'approved') {
                setStep(5);
                toast('Pagamento realizado com sucesso!');
                navigate('/passo6');
              }
              if (
                json.status_detail === 'cc_rejected_bad_filled_security_code'
              ) {
                toast.error(
                  'Ops! Código de segurança inválido. Tente novamente!'
                );
              }
              if (json.status_detail === 'cc_rejected_insufficient_amount') {
                toast.error('Ops! Saldo insuficiente!');
              }
            });
          })
          .catch(() => {
            toast.error(
              'Ops! Tente novamente ou entre em contato com sua operadora!'
            );
          });
      },
      onFetching: (resource: any) => {
        console.log('Fetching resource: ', resource);

        // Animate progress bar
        const progressBar = document.querySelector('.progress-bar');
        progressBar?.removeAttribute('value');

        return () => {
          progressBar?.setAttribute('value', '0');
        };
      },
    },
  });

  useEffect(() => {
    if (payWithPix) {
      (async function getIdentificationTypes() {
        try {
          const identificationTypes =
            await mercadopago?.getIdentificationTypes();
          const identificationTypeElement = document.getElementById(
            'form-checkout__identificationType2'
          );

          createSelectOptions(identificationTypeElement, identificationTypes);
        } catch (e) {
          return console.error('Error getting identificationTypes: ', e);
        }
      })();
    }
  }, [mercadopago, payWithPix]);

  function createSelectOptions(
    elem: any,
    options: any,
    labelsAndKeys = { label: 'name', value: 'id' }
  ) {
    const { label, value } = labelsAndKeys;

    elem.options.length = 0;

    const tempOptions = document.createDocumentFragment();

    options.forEach((option: any) => {
      const optValue = option[value];
      const optLabel = option[label];

      const opt = document.createElement('option');
      opt.value = optValue;
      opt.textContent = optLabel;

      tempOptions.appendChild(opt);
    });

    elem.appendChild(tempOptions);
  }

  const setMethodPayment = useCallback((metodo: string) => {
    if (metodo === 'cartao') {
      setPayWithCreditCard(true);
      setPayWithPix(false);
      setCupom(false);
    }
    if (metodo === 'pix') {
      setPayWithCreditCard(false);
      setPayWithPix(true);
      setCupom(false);
    }
    if (metodo === 'cupom') {
      setPayWithCreditCard(false);
      setPayWithPix(false);
      setCupom(true);
    }
  }, []);

  const handlePaymentPix = useCallback(async () => {
    api
      .post('payments/createPix', {
        transaction_amount: amout,
        subscription_id: subscription.id,
        description: descri,
        payment_method_id,
        email,
        first_name,
        last_name,
        type,
        number,
      })
      .then((res) => {
        setResPix(res.data);
      })
      .catch((e) => console.log(e));
  }, [
    first_name,
    last_name,
    email,
    type,
    number,
    payment_method_id,
    amout,
    descri,
    subscription,
  ]);

  const handleCupomSubmit = useCallback(async () => {
    api
      .patch('/subscription/coupom', {
        code_coupom: code,
        subscription_id: subscription.id,
        current_step: 6,
      })
      .then((res) => {
        if (res.status === 200) {
          setSubscription(res.data);
          if (subscription.current_step < 6) {
            setStep(6);
          }
          toast.success('Cupom resgatado com sucesso!');
          navigate('/passo7');
        }
      })
      .catch((e) => {
        console.log(e);
        return toast.error(`${e}`);
      });
  }, [setStep, navigate, code, subscription, setSubscription]);

  useEffect(() => {
    if (
      resPix.transaction_id !== undefined &&
      resPix.transaction_id !== null &&
      time > 0
    ) {
      setTimeout(async () => {
        setTime(time - 1);
        api
          .post('payments/check', {
            transaction_id: resPix.transaction_id,
          })
          .then((res) => {
            if (res.data.status !== false) {
              setStep(6);
              toast.success('Pix realizado com sucesso!');
              navigate('/passo7');
            }
          });
      }, 5000);
    }
  }, [resPix, time, navigate, setStep]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Pagamento da inscrição' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxInfoMercadoPago>
            <img src={Mpago} alt='mercado pago' />
            <label>
              Pague mais rápido com seus cartões salvos sem precisar preencher
              dados.
            </label>
          </C.BoxInfoMercadoPago>
          {/* <div style={{
            background: '#2F80ED',
            padding: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            height: '45px'
          }} >
            <p
              style={{cupom
                fontSize: '1rem',
                color: '#fff',
              }}
            >
              PAGAR COM O MERCADO PAGO
            </p>
          </div> */}
          {subscription.current_step < 6 && (
            <>
              {config.configCupom && (
                <C.BoxDataCard>
                  <C.BoxHeader>
                    <RiCoupon2Line fontSize={20} color='#525252' />
                    <label>Adicionar cupom</label>
                    <CheckBox
                      value={cupom}
                      onValueChange={() => setMethodPayment('cupom')}
                    />
                  </C.BoxHeader>

                  {cupom && (
                    <>
                      <C.LabelInfo>Adicione o cupom</C.LabelInfo>
                      <C.GroupInputs>
                        <Input
                          label='Possui um cupom de patrocinador?'
                          width='100%'
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                        <button onClick={handleCupomSubmit}>APLICAR</button>
                      </C.GroupInputs>
                    </>
                  )}
                </C.BoxDataCard>
              )}

              {config.configCard && (
                <C.BoxDataCard>
                  <C.BoxHeader>
                    <BiCreditCard fontSize={20} color='#525252' />
                    <label>Pagar com cartão de crédito</label>
                    <CheckBox
                      value={payWithCreditCard}
                      onValueChange={() => setMethodPayment('cartao')}
                    />
                  </C.BoxHeader>

                  {payWithCreditCard && (
                    <>
                      <C.LabelInfo>Preencha os dados do seu cartão</C.LabelInfo>
                      <br />
                      <br />
                      <form id='form-checkout'>
                        <label>Número do cartão</label>
                        <div
                          id='form-checkout__cardNumber'
                          className='container'
                        ></div>
                        <label>MM/YYYY</label>
                        <div
                          id='form-checkout__expirationDate'
                          className='container'
                        ></div>
                        <label>Código de segurança</label>
                        <div
                          id='form-checkout__securityCode'
                          className='container'
                        ></div>
                        <label>Titular do cartão</label>
                        <input type='text' id='form-checkout__cardholderName' />
                        <label>Banco emissor</label>
                        <select id='form-checkout__issuer'></select>
                        <label>Parcelas</label>
                        <select id='form-checkout__installments'></select>
                        <label>Tipo de documento</label>
                        <select id='form-checkout__identificationType'></select>
                        <label>Número do documento</label>
                        <input type='text' id='2' />
                        <label>E-mail</label>
                        <input
                          type='email'
                          id='form-checkout__cardholderEmail'
                        />

                        <button type='submit' id='form-checkout__submit'>
                          Pagar
                        </button>
                        <progress value='0' className='progress-bar'>
                          Carregando...
                        </progress>
                      </form>

                      <C.BoxFooterDataCard>
                        Ao continuar, você concorda com nossos{' '}
                        <label>Termos de condições</label>
                      </C.BoxFooterDataCard>
                    </>
                  )}
                </C.BoxDataCard>
              )}

              {config.configPix && (
                <C.BoxDataCard>
                  <C.BoxHeader>
                    <img src={Pix} alt='PIX' />
                    <label>Pagar com PIX</label>
                    <CheckBox
                      value={payWithPix}
                      onValueChange={() => setMethodPayment('pix')}
                    />
                  </C.BoxHeader>

                  {payWithPix && (
                    <>
                      <C.LabelInfo>Dados para o PIX</C.LabelInfo>
                      <br />
                      <br />
                      {resPix.qr_code === undefined && (
                        <form id='form-checkout2'>
                          <label>Nome</label>
                          <input
                            id='form-checkout__payerFirstName'
                            name='payerFirstName'
                            type='text'
                            value={first_name}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                          />
                          <label>Sobrenome</label>
                          <input
                            id='form-checkout__payerLastName'
                            name='payerLastName'
                            type='text'
                            value={last_name}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                          <label>E-mail</label>
                          <input
                            id='form-checkout__email'
                            name='email'
                            type='text'
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                          <label>Tipo de documento</label>
                          <select
                            id='form-checkout__identificationType2'
                            name='identificationType'
                            value={type}
                            onChange={(e) => {
                              setType(e.target.value);
                            }}
                          ></select>
                          <label>Número do documento</label>
                          <input
                            id='form-checkout__identificationNumber2'
                            name='identificationNumber'
                            type='text'
                            value={number}
                            onChange={(e) => {
                              setNumber(e.target.value);
                            }}
                          />

                          <input
                            type='hidden'
                            name='transactionAmount'
                            id='transactionAmount'
                            value='100'
                          />
                          <input
                            type='hidden'
                            name='description'
                            id='description'
                            value='Inscrição Getap'
                          />
                          <br />
                          <button type='button' onClick={handlePaymentPix}>
                            Pagar
                          </button>
                        </form>
                      )}
                    </>
                  )}
                  {payWithPix && resPix.qr_code !== undefined && (
                    <C.ContentQRC>
                      <div className='infoPay'>
                        <span>Aguarde!</span>
                        <p>
                          Assim que o pagamento for compensado você será
                          redirecionado
                        </p>
                      </div>
                      <img
                        src={`data:image/jpeg;base64,${resPix.qr_code_base64}`}
                        alt='QRCode'
                      />
                      <label>Copiar Hash:</label>
                      <input type='text' id='copiar' value={resPix.qr_code} />
                    </C.ContentQRC>
                  )}
                </C.BoxDataCard>
              )}
            </>
          )}
          {subscription.current_step >= 6 &&
            (subscription.coupom_id !== null ||
              subscription.payment_id !== null) && (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '5rem',
                }}
              >
                <BiCheckCircle fontSize={106} color='#27AE60' />
                <Title
                  title={
                    subscription.coupom_id !== null
                      ? 'Cupom foi resgatado com sucesso!'
                      : 'Pagamento já foi realizado!'
                  }
                />
              </div>
            )}
          <C.BoxInfoDataSelf>
            Os seus dados pessoais serão utilizados para processar a sua compra,
            apoiar a sua experiência em todo este site e para outros fins
            descritos na nossa <label>política de privacidade</label>
            <br />
            <b>
              Não há possibilidade de devolução após o passo atual. Se houver
              dúvida, entre em contato por nosso WhatsApp.
            </b>
          </C.BoxInfoDataSelf>
          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate('/passo5')}
              title='VOLTAR'
              color='#2F80ED'
            />
            {subscription.current_step >= 6 && (
              <Button
                onClick={() => navigate('/passo7')}
                title='AVANÇAR'
                color='#27AE60'
                iconRight={<BiChevronRight fontSize={22} />}
              />
            )}
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
