/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight, BiPlusCircle } from 'react-icons/bi';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { useCallback, useEffect, useState } from 'react';
import { NumberBox } from 'devextreme-react';
import { InfoHibrido } from '../../data/data';
import { Tooltip } from '../../components/Tooltip';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from '../../store';
import AutoCompleteCustom from '../../components/AutoCompleteCustom';
import { Hybrid } from '../../store/types';
// import hibridoData from '../../data/hibridos.json';

export interface ICulture {
  nome: string;
  area: string;
  sistema: string;
  id?: number;
}

export const FormStep7 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);
  const hibridoData = useAppStore((store) => store.hybrids);

  const [area_total_milho, setAreaTotalMilho] = useState(
    subscription.property?.total_area_corn
  );
  const [qtd_hibridos, setQtdHibridos] = useState(
    subscription.property?.hybrid_quantity
  );
  const [hibrido_1, setHibrido1] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[0]?.hybrid
  );
  const [hibrido_2, setHibrido2] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[1]?.hybrid
  );
  const [hibrido_3, setHibrido3] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[2]?.hybrid
  );
  const [hibrido_4, setHibrido4] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[3]?.hybrid
  );
  const [hibrido_5, setHibrido5] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[4]?.hybrid
  );
  const [hibrido_6, setHibrido6] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[5]?.hybrid
  );

  const [hibrido_7, setHibrido7] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[6]?.hybrid
  );
  const [hibrido_8, setHibrido8] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[7]?.hybrid
  );
  const [hibrido_9, setHibrido9] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[8]?.hybrid
  );

  const [hibrido_10, setHibrido10] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[9]?.hybrid
  );
  const [hibrido_11, setHibrido11] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[10]?.hybrid
  );
  const [hibrido_12, setHibrido12] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[11]?.hybrid
  );
  const [hibrido_13, setHibrido13] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[12]?.hybrid
  );
  const [hibrido_14, setHibrido14] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[13]?.hybrid
  );
  const [hibrido_15, setHibrido15] = useState<Hybrid | undefined>(
    subscription?.hybridies_subscription[14]?.hybrid
  );

  const navigate = useNavigate();

  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');

  useEffect(() => {
    if (subscription_id !== null && subscription_id !== subscription.id) {
      loadSubscription(subscription_id);
    }
    setAreaTotalMilho(subscription.property?.total_area_corn);
    setQtdHibridos(subscription.property?.hybrid_quantity);
    setHibrido1(subscription?.hybridies_subscription[0]?.hybrid);
    setHibrido2(subscription?.hybridies_subscription[1]?.hybrid);
    setHibrido3(subscription?.hybridies_subscription[2]?.hybrid);
    setHibrido4(subscription?.hybridies_subscription[3]?.hybrid);
    setHibrido5(subscription?.hybridies_subscription[4]?.hybrid);
    setHibrido6(subscription?.hybridies_subscription[5]?.hybrid);
    setHibrido7(subscription?.hybridies_subscription[6]?.hybrid);
    setHibrido8(subscription?.hybridies_subscription[7]?.hybrid);
    setHibrido9(subscription?.hybridies_subscription[8]?.hybrid);
    setHibrido10(subscription?.hybridies_subscription[9]?.hybrid);
    setHibrido11(subscription?.hybridies_subscription[10]?.hybrid);
    setHibrido12(subscription?.hybridies_subscription[11]?.hybrid);
    setHibrido13(subscription?.hybridies_subscription[12]?.hybrid);
    setHibrido14(subscription?.hybridies_subscription[13]?.hybrid);
    setHibrido15(subscription?.hybridies_subscription[14]?.hybrid);
  }, [subscription, loadSubscription, subscription_id]);

  const getHibridoValue = (index: number) => {
    switch (index) {
      case 1:
        return hibrido_1;
      case 2:
        return hibrido_2;
      case 3:
        return hibrido_3;
      case 4:
        return hibrido_4;
      case 5:
        return hibrido_5;
      case 6:
        return hibrido_6;
      case 7:
        return hibrido_7;
      case 8:
        return hibrido_8;
      case 9:
        return hibrido_9;
      case 10:
        return hibrido_10;
      case 11:
        return hibrido_11;
      case 12:
        return hibrido_12;
      case 13:
        return hibrido_13;
      case 14:
        return hibrido_14;
      case 15:
        return hibrido_15;
      default:
        return '';
    }
  };

  // Função para verificar se as variáveis até o valor passado por parâmetro foram alteradas
  const verificarHibridos = () => {
    if (qtd_hibridos === undefined || qtd_hibridos === 0) {
      return true;
    }
    for (let x = 1; x <= qtd_hibridos; x++) {
      if (
        getHibridoValue(x) === undefined ||
        getHibridoValue(x) === null ||
        getHibridoValue(x) === ''
      ) {
        return false;
      }
    }
    return true;
  };

  const formatArrayHybrids = useCallback(() => {
    const hybridStates = [
      hibrido_1,
      hibrido_2,
      hibrido_3,
      hibrido_4,
      hibrido_5,
      hibrido_6,
      hibrido_7,
      hibrido_8,
      hibrido_9,
      hibrido_10,
      hibrido_11,
      hibrido_12,
      hibrido_13,
      hibrido_14,
      hibrido_15,
    ];

    const hybrids = Array.from({ length: qtd_hibridos || 0 })
      .map((_, index) => {
        const hybrid = hybridStates[index];
        return hybrid ? { hybrid_id: hybrid.id } : null;
      })
      .filter(Boolean);

    return hybrids;
  }, [
    hibrido_1,
    hibrido_2,
    hibrido_3,
    hibrido_4,
    hibrido_5,
    hibrido_6,
    hibrido_7,
    hibrido_8,
    hibrido_9,
    hibrido_10,
    hibrido_11,
    hibrido_12,
    hibrido_13,
    hibrido_14,
    hibrido_15,
  ]);

  const handleSubmit = useCallback(async () => {
    if (
      area_total_milho === undefined ||
      area_total_milho === null ||
      qtd_hibridos === undefined ||
      qtd_hibridos === null
    ) {
      return toast.error('Preencha todos os campos obrigatórios!');
    }

    if (!verificarHibridos()) {
      return toast.error(`É preciso informar ${qtd_hibridos} híbridos!`);
    }

    console.log(formatArrayHybrids());

    api
      .patch('/subscription/hybrids', {
        subscription_id: subscription.id,
        current_step: 7,
        total_area_corn: area_total_milho,
        hybrid_quantity: qtd_hibridos,
        hybrids: formatArrayHybrids(),
      })
      .then((res) => {
        if (res.status === 200) {
          setSubscription(res.data);
          if (subscription.current_step < 7) {
            setStep(7);
          }
          toast.success('Informações salvas com sucesso!');
          navigate('/passo8');
        }
      });
  }, [
    subscription,
    area_total_milho,
    qtd_hibridos,
    hibrido_1,
    hibrido_2,
    hibrido_3,
    hibrido_4,
    hibrido_5,
    hibrido_6,
    hibrido_7,
    hibrido_8,
    hibrido_9,
    hibrido_10,
    hibrido_11,
    hibrido_12,
    hibrido_13,
    hibrido_14,
    hibrido_15,
    verificarHibridos,
    setSubscription,
    navigate,
    setStep,
  ]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Informações da Propriedade' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            <C.ContentRightFormBoxLeft></C.ContentRightFormBoxLeft>

            <C.BoxInput>
              <NumberBox
                value={
                  area_total_milho !== undefined && area_total_milho !== null
                    ? Number(area_total_milho)
                    : undefined
                }
                onValueChange={(e) => setAreaTotalMilho(e !== '' ? e : 0)}
                stylingMode='underlined'
                labelMode='static'
                label='Área total de milho semeada na safra atual (*)'
                height={50}
              />
              <br />
            </C.BoxInput>

            <C.LabelInfo>
              Quais híbridos de milho está sendo cultivado na safra atual? (*){' '}
              <Tooltip info={InfoHibrido} icon={<AiFillQuestionCircle />} />{' '}
            </C.LabelInfo>

            <C.BoxInputWithBtnPlus>
              <NumberBox
                value={qtd_hibridos !== undefined ? qtd_hibridos : undefined}
                onValueChange={setQtdHibridos}
                stylingMode='underlined'
                labelMode='static'
                label='Quantidade de hibridos usados '
                width='50%'
                height={50}
              />
              <span>
                <BiPlusCircle
                  fontSize={28}
                  style={{ marginRight: 10 }}
                  onClick={() => {}}
                />
              </span>
            </C.BoxInputWithBtnPlus>

            {Number(qtd_hibridos) >= 1 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_1 !== undefined ? hibrido_1?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 1'
                  onValueChanged={(e) => {
                    setHibrido1(e);
                    console.log(e);
                  }}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}
            {Number(qtd_hibridos) >= 2 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_2 !== undefined ? hibrido_2?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 2'
                  onValueChanged={(e) => setHibrido2(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}
            {Number(qtd_hibridos) >= 3 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_3 !== undefined ? hibrido_3?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 3'
                  onValueChanged={(e) => setHibrido3(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}
            {Number(qtd_hibridos) >= 4 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_4 !== undefined ? hibrido_4?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 4'
                  onValueChanged={(e) => setHibrido4(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}
            {Number(qtd_hibridos) >= 5 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_5 !== undefined ? hibrido_5?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 5'
                  onValueChanged={(e) => setHibrido5(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}
            {Number(qtd_hibridos) >= 6 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_6 !== undefined ? hibrido_6?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 6'
                  onValueChanged={(e) => setHibrido6(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 7 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_7 !== undefined ? hibrido_7?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 7'
                  onValueChanged={(e) => setHibrido7(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 8 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_8 !== undefined ? hibrido_8?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 8'
                  onValueChanged={(e) => setHibrido8(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 9 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_9 !== undefined ? hibrido_9?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 9'
                  onValueChanged={(e) => setHibrido9(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 10 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_10 !== undefined ? hibrido_10?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 10'
                  onValueChanged={(e) => setHibrido10(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 11 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_11 !== undefined ? hibrido_11?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 11'
                  onValueChanged={(e) => setHibrido11(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 12 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_12 !== undefined ? hibrido_12?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 12'
                  onValueChanged={(e) => setHibrido12(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 13 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_13 !== undefined ? hibrido_13?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 13'
                  onValueChanged={(e) => setHibrido13(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 14 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_14 !== undefined ? hibrido_14?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 14'
                  onValueChanged={(e) => setHibrido14(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 15 && (
              <C.BoxInput>
                <AutoCompleteCustom<Hybrid>
                  itemsAvailable={hibridoData}
                  valueExpr='name'
                  searchExpr='name'
                  inputValue={
                    hibrido_15 !== undefined ? hibrido_15?.name : undefined
                  }
                  height={50}
                  label='Nome do hibrido 15'
                  onValueChanged={(e) => setHibrido15(e)}
                  labelMode='static'
                  stylingMode='underlined'
                  typeData='hibrido'
                  searchMode='startswith'
                />
              </C.BoxInput>
            )}
          </C.BoxFields>

          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate('/passo6')}
              title='VOLTAR'
              color='#2F80ED'
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmit}
              title='AVANÇAR'
              color='#27AE60'
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
