/* eslint-disable react/jsx-no-target-blank */
import 'devextreme/dist/css/dx.light.css';
import { ReactNode, useState } from 'react';
import { BiXCircle, BiCheckCircle, BiChevronRight } from 'react-icons/bi';
import { SiWhatsapp } from 'react-icons/si';
import { Button } from '../Button/Button';
import { Header } from '../Header/Header';
import { Title } from '../Title/Title';
import * as C from './styles';
import { locale } from 'devextreme/localization';

import { useAuth } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';
import { InitModal } from '../InitModal';
// import api from '../../services/api';
import { useAppStore } from '../../store';
import { ISubscription } from '../../store/types';

type Props = {
  children: ReactNode;
};

export const Theme = ({ children }: Props) => {
  const subscription = useAppStore((store) => store.subscription);
  const subscriptions = useAppStore((store) => store.subscriptions);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);
  const config = useAppStore((store) => store.config);
  const clearSubscription = useAppStore((store) => store.clearSubscription);

  const notAllowedNewSubscription = useAppStore(
    (store) => store.notAllowedNewSubscription
  );

  const { user } = useAuth();
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();

  locale(navigator.language);

  async function handleLoadData() {
    if (notAllowedNewSubscription) {
      setModal(true);
      return;
    }
    setStep(1);
    navigate('/passo2');
  }

  async function confirmContinue() {
    const sub = subscriptions.filter(
      (sub: ISubscription) => sub.status === 'OPEN'
    )[0];
    setSubscription(sub);
    setModal(false);
    navigate(`/passo${sub.current_step + 1}`);
  }

  async function confirmNewSubscription() {
    // const sub = subscriptions.filter(
    //   (sub: ISubscription) => sub.status === 'OPEN'
    // )[0];
    clearSubscription();
    // await api.post(`/subscription/cancel`, { subscription_id: sub.id });

    setModal(false);
    setStep(1);
    navigate('/passo2');
  }

  return (
    <C.Container>
      {modal && (
        <InitModal
          titleHeader='Atenção!'
          title='Inscrição iniciada'
          desc='Você tem uma incrição incompleta, deseja continuar ou iniciar uma nova?'
          onClose={() => {
            setModal(false);
          }}
          mxheight='40vh'
        >
          <div className='content-button'>
            <Button
              color='#2758ae'
              title='CONTINUAR'
              onClick={confirmContinue}
              iconRight={<BiChevronRight fontSize={22} />}
            />
            <Button
              color='#27AE60'
              title='NOVA INSCRIÇÃO'
              onClick={confirmNewSubscription}
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </div>
        </InitModal>
      )}
      <Header />

      <C.Area>
        <C.Steps>
          <C.Sidebar>
            <C.ContentLeft>
              <Title title='Inscrição' />
              {user && subscription.current_step === 0 && (
                <Button
                  color={config.configSub ? '#27AE60' : '#ec7a7a'}
                  title={
                    config.configSub ? 'NOVA INSCRIÇÃO' : 'INCRIÇŌES ENCERRADAS'
                  }
                  onClick={config.configSub ? handleLoadData : () => {}}
                  iconRight={<BiChevronRight fontSize={22} />}
                />
              )}

              {user && subscription.current_step > 0 && (
                <C.CheckSteps>
                  <C.ContentStep
                    color={subscription.current_step >= 1 ? '1' : '0'}
                  >
                    {subscription.current_step >= 1 ? (
                      <BiCheckCircle
                        fontSize={20}
                        style={{ marginRight: 10 }}
                      />
                    ) : (
                      <BiXCircle fontSize={20} style={{ marginRight: 10 }} />
                    )}{' '}
                    Dados pessoais e da propriedade
                  </C.ContentStep>
                  <C.ContentStep
                    color={subscription.current_step >= 4 ? '1' : '0'}
                  >
                    {subscription.current_step >= 4 ? (
                      <BiCheckCircle
                        fontSize={20}
                        style={{ marginRight: 10 }}
                      />
                    ) : (
                      <BiXCircle fontSize={20} style={{ marginRight: 10 }} />
                    )}{' '}
                    Aceite do regulamento
                  </C.ContentStep>
                  <C.ContentStep
                    color={subscription.current_step >= 5 ? '1' : '0'}
                  >
                    {subscription.current_step >= 5 ? (
                      <BiCheckCircle
                        fontSize={20}
                        style={{ marginRight: 10 }}
                      />
                    ) : (
                      <BiXCircle fontSize={20} style={{ marginRight: 10 }} />
                    )}{' '}
                    Pagamento
                  </C.ContentStep>
                  <C.ContentStep
                    color={subscription.current_step >= 10 ? '1' : '0'}
                  >
                    {subscription.current_step >= 10 ? (
                      <BiCheckCircle
                        fontSize={20}
                        style={{ marginRight: 10 }}
                      />
                    ) : (
                      <BiXCircle fontSize={20} style={{ marginRight: 10 }} />
                    )}{' '}
                    Informações da área inscrita
                  </C.ContentStep>
                  <C.ContentStep
                    color={subscription.current_step >= 12 ? '1' : '0'}
                  >
                    {subscription.current_step >= 12 ? (
                      <BiCheckCircle
                        fontSize={20}
                        style={{ marginRight: 10 }}
                      />
                    ) : (
                      <BiXCircle fontSize={20} style={{ marginRight: 10 }} />
                    )}{' '}
                    Agendamento de auditoria
                  </C.ContentStep>
                </C.CheckSteps>
              )}
            </C.ContentLeft>
          </C.Sidebar>
          <C.Page>{children}</C.Page>
        </C.Steps>
      </C.Area>

      <C.WhatsApp>
        <a
          href='https://api.whatsapp.com/send/?phone=%2B5534991941413&text=Ol%C3%A1%2C+gostaria+de+atendimento+por+favor%21&type=phone_number&app_absent=0'
          target={'_blank'}
        >
          <SiWhatsapp />
        </a>
      </C.WhatsApp>
    </C.Container>
  );
};
