/* eslint-disable react-hooks/exhaustive-deps */
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import 'devextreme/dist/css/dx.light.css';
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import { CategorieSubscribe, YesNot } from '../../data/data';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from '../../store';
import AutoCompleteCustom from '../../components/AutoCompleteCustom';
import { Hybrid } from '../../store/types';

export const FormStep8 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);
  const hibridoData = useAppStore((store) => store.hybrids);

  const navigate = useNavigate();
  const [mesmo_talhao, setMesmoTalhao] = useState<Boolean | undefined>(
    subscription?.property?.is_same_previes_contest
  );

  const [category, setCategory] = useState(subscription.property?.category);
  const [nome_talha, setNomeTalha] = useState(subscription.property?.plot_name);
  const [hibrido, setHibrido] = useState<Hybrid | undefined>(
    subscription.property?.hybrid
  );
  const [expec_produtividade, setExpecProdutividade] = useState(
    subscription.property?.productivity
  );

  const [custo_producao, setCustoProducao] = useState(
    subscription.property?.productivity_cost_media
  );
  const [altitude, setAltitude] = useState(subscription.property?.plot_height);
  const [latitude, setLatitude] = useState(subscription.property?.lat);
  const [longitude, setLongitude] = useState(subscription.property?.long);

  const subscription_id = localStorage.getItem('@GetapAPP:subscription_id');

  useEffect(() => {
    if (subscription_id !== null && subscription_id !== subscription.id) {
      loadSubscription(subscription_id);
    }

    setMesmoTalhao(subscription.property?.is_same_previes_contest);
    setCategory(subscription.property?.category);
    setNomeTalha(subscription.property?.plot_name);
    setHibrido(subscription.property?.hybrid);
    setExpecProdutividade(subscription.property?.productivity);

    setAltitude(subscription.property?.plot_height);
    setCustoProducao(subscription.property?.productivity_cost_media);
    setLatitude(subscription.property?.lat);
    setLongitude(subscription.property?.long);
  }, [subscription, loadSubscription, subscription_id]);

  const handleSubmit = useCallback(async () => {
    if (
      nome_talha === undefined ||
      nome_talha === '' ||
      category === undefined ||
      hibrido === undefined ||
      expec_produtividade === undefined
    ) {
      return toast.error('Preencha os campos obrigatórios!');
    }
    api
      .patch('/subscription/add-history', {
        subscription_id: subscription.id,
        current_step: 8,
        is_same_previes_contest: mesmo_talhao,
        plot_name: nome_talha,
        category,
        hybrid_id: hibrido.id,
        productivity: expec_produtividade,
        productivity_cost_media: Number(custo_producao),
        lat: Number(latitude),
        long: Number(longitude),
        plot_height: Number(altitude),
      })
      .then((res) => {
        if (res.status === 200) {
          setSubscription(res.data);
          if (subscription.current_step < 8) {
            setStep(8);
          }
          toast.success('Historico salvo com sucesso!');
          navigate('/passo9');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      });
  }, [
    subscription,
    category,
    nome_talha,
    hibrido,
    expec_produtividade,
    custo_producao,
    altitude,
    latitude,
    longitude,
    mesmo_talhao,
  ]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Informações da área inscrita' />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>
        <C.ContentRightForm>
          <C.InfoSection>Histórico da área</C.InfoSection>
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.WrapperCheckbox>
                <SelectBox
                  value={mesmo_talhao ? '1 - Sim' : '0 - Não'}
                  onValueChange={(e) => {
                    setMesmoTalhao(e === '1 - Sim');
                  }}
                  selectedItem={mesmo_talhao ? '1 - Sim' : '0 - Não'}
                  items={YesNot}
                  stylingMode='underlined'
                  labelMode='static'
                  label='É o mesmo talhão do concurso anterior?'
                  width='400'
                  height={50}
                />
              </C.WrapperCheckbox>

              <C.BoxInput>
                <TextBox
                  value={nome_talha}
                  onValueChange={setNomeTalha}
                  stylingMode='underlined'
                  labelMode='static'
                  label='Nome do talhão inscrito (*)'
                  height={50}
                />
                <br />
              </C.BoxInput>
              <C.GroupInputsCatHibrid>
                <C.BoxInput>
                  <SelectBox
                    value={category}
                    items={CategorieSubscribe}
                    onValueChange={setCategory}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Categoria inscrita (*)'
                    placeholder='Selecione'
                    width='100%'
                    height={50}
                  />
                  <br />
                </C.BoxInput>
                <C.BoxInput>
                  <AutoCompleteCustom<Hybrid>
                    itemsAvailable={hibridoData}
                    valueExpr='name'
                    searchExpr='name'
                    inputValue={
                      hibrido !== undefined ? hibrido?.name : undefined
                    }
                    width='100%'
                    height={50}
                    label='Hibrido inscrito (*)'
                    onValueChanged={(e) => setHibrido(e)}
                    labelMode='static'
                    stylingMode='underlined'
                    typeData='hibrido'
                    searchMode='startswith'
                  />
                  <br />
                </C.BoxInput>
              </C.GroupInputsCatHibrid>
              <C.GroupInputsCatHibrid>
                <C.BoxInputWithBtnPlus style={{ margin: 0 }}>
                  <NumberBox
                    value={
                      expec_produtividade === undefined
                        ? undefined
                        : expec_produtividade
                    }
                    onValueChange={setExpecProdutividade}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Qual a expectativa de produtividade da área inscrita em sc/ha? (*)'
                    width='100%'
                    height={50}
                    max={999999}
                  />
                </C.BoxInputWithBtnPlus>
                <C.BoxInput>
                  <NumberBox
                    value={custo_producao === null ? undefined : custo_producao}
                    onValueChange={setCustoProducao}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Em média, qual o custo de produção do talhão?(R$/ha)'
                    width='100%'
                    height={50}
                  />
                </C.BoxInput>
              </C.GroupInputsCatHibrid>
              <C.GroupInputsCatHibrid>
                <C.BoxInput>
                  <NumberBox
                    value={latitude === null ? undefined : latitude}
                    onValueChange={setLatitude}
                    stylingMode='underlined'
                    labelMode='static'
                    placeholder='-19.263559006'
                    label='Qual é a latitude do talhão?'
                    width='100%'
                    height={50}
                  />
                  <br />
                </C.BoxInput>
                <C.BoxInput>
                  <NumberBox
                    value={longitude === null ? undefined : longitude}
                    placeholder='-48.28172108'
                    onValueChange={setLongitude}
                    label='Qual é a longitude do talhão?'
                    height={50}
                    stylingMode='underlined'
                  />
                  <br />
                </C.BoxInput>
                <C.BoxInput>
                  <NumberBox
                    value={altitude === null ? undefined : altitude}
                    onValueChange={setAltitude}
                    stylingMode='underlined'
                    labelMode='static'
                    label='Qual é a altitude do talhão?'
                    width='100%'
                    height={50}
                  />
                  <br />
                </C.BoxInput>
              </C.GroupInputsCatHibrid>
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>

          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate('/passo7')}
              title='VOLTAR'
              color='#2F80ED'
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmit}
              title='SALVAR E AVANÇAR'
              color='#27AE60'
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
